export default class DetailSlider {
  constructor(element, options){
    console.log(options)
    this.element = element
    this.options = options
    this.slider = $(this.element).find(".p-product-detail__slider__thumb")
    this.navi = $(this.element).find(".p-product-detail__slider__nav__inner")
    this.sliderNext = $(this.element).find('.p-product-detail__slider__arrow--right')
    this.sliderPrev = $(this.element).find('.p-product-detail__slider__arrow--left')
    this.currentSlide = $(this.element).find('.slide_current')
    this.totalSlide = $(this.element).find('.slide_total')
    this.init()
  }
  init(){
    this.slickStart()
  }

  slickStart() {
    $(this.slider).on('init', (event, slick) => {
      this.setCount(slick)
    })
    
    $(this.slider).slick({
      asNavFor: this.navi,
      slidesToShow: 1,
      arrows: false,
      fade: true,
      draggable: false,
      infinite: false,
      responsive: [{
        breakpoint: 900,
        settings: {
          asNavFor: null,
          draggable: true,
          dots: true,
          swipeToSlide: true,
          infinite: true,
          centerMode: true,
          centerPadding: '0',
          fade: false,
          infinite: false
        }
      }],
    });
    $(this.navi).slick({
      asNavFor: this.slider,
      slidesToShow: 5,
      focusOnSelect: true,
      prevArrow: this.sliderPrev,
      nextArrow: this.sliderNext,
      swipeToSlide: true,
      infinite: false
    });

    $(this.navi).on('afterChange', (event, slick, currentSlide, nextSlide, slideCount) => {
      this.setCurrent(currentSlide)
    })
  }

  setCount(slick) {
    $(this.totalSlide).text(slick.slideCount)
  }

  setCurrent(current) {
    $(this.currentSlide).text(current+1)
  }
}
