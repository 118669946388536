export default class Notification {
  constructor(element) {
    this.element = element
    this.button = $(this.element).find('.l-notification__close')
    this.height = $(this.element).outerHeight()
    this.header = $('.l-header')
    this.flg = false
    this.closed = false
    this.element == null ? '' : this.init()
  }

  init() {
    this.bottomOffset = this.element.getBoundingClientRect().bottom
    this.close()
    this.setPosition()
    window.addEventListener('scroll', () => this.scroll())
  }

  setPosition() {
    this.main = $('.l-main')
    const currentPadding = parseInt($(this.main).css('padding-top'), 10)
    this.initPadding = currentPadding
    const currentTop = parseInt($(this.header).css('top'), 10)
    this.initTop = currentTop
    $(this.main).css('padding-top', currentPadding+this.height)
    $(this.header).css('top', currentTop+this.height)
    $(this.header).css('position', 'absolute')
  }

  close() {
    $(this.button).click(() => {
      $(this.element).css('display', 'none');
      const currentPadding = parseInt($(this.main).css('padding-top'), 10)
      const currentTop = parseInt($(this.header).css('top'), 10)
      $(this.main).css('padding-top', currentPadding-this.height)
      $(this.header).css('top', currentTop-this.height)
      this.closed = true
    })
  }

  scroll() {
    if (!this.flg) {
      if (window.pageYOffset > this.bottomOffset) {
        this.flg = true
        console.log('fixed')
        // $(this.element).animate({'opacity': 0},{'duration': 500, 'easing': 'swing'})
        // $(this.header).animate({'top': 0},{'duration': 500, 'easing': 'swing'})
        // $(this.main).animate({'paddingTop': this.initPadding},{'duration': 500, 'easing': 'swing'})
        $(this.header).css('position', 'fixed')
        $(this.header).css('top', 0)
      }
    }
    if (this.flg && this.closed == false) {
      if (window.pageYOffset <= this.bottomOffset) {
        this.flg = false
        console.log('absolute')
        // $(this.element).animate({'opacity': 1},{'duration': 500, 'easing': 'swing'})
        // $(this.header).animate({'top': this.initTop+this.height},{'duration': 500, 'easing': 'swing'})
        // $(this.main).animate({'paddingTop': this.initPadding+this.height},{'duration': 500, 'easing': 'swing'})
        $(this.header).css('position', 'absolute')
        $(this.header).css('top', this.height)
      }
    }
    if (this.flg && this.closed == true) {
      if (window.pageYOffset <= this.bottomOffset) {
        this.flg = false
        console.log('absolute')
        // $(this.element).animate({'opacity': 1},{'duration': 500, 'easing': 'swing'})
        // $(this.header).animate({'top': this.initTop+this.height},{'duration': 500, 'easing': 'swing'})
        // $(this.main).animate({'paddingTop': this.initPadding+this.height},{'duration': 500, 'easing': 'swing'})
        $(this.header).css('position', 'absolute')
        $(this.header).css('top', 0)
      }
    }
  }
}
