export default class KvSlider {
  constructor(element, options){
    this.element = element
    this.options = options
    this.dots = this.options["dots"]
    this.init()
  }
  init(){
    this.slickStart()
    this.slides = this.element.querySelectorAll('.p-top-section__kv__slide-frame')
    // window.addEventListener('resize', this.setSliderSize.bind(this))
  }

  slickStart() {
    $(this.element).on('init', (event, slick) => {
      this.slides = this.element.querySelectorAll('.p-top-section__kv__slide-frame')
      // this.setSliderSize()
    })

    $(this.element).slick({
      slideToShow: 1,
      arrows: false,
      dots: this.dots,
      autoplay: true,
      autoplaySpeed: 8000,
      infinite: true,
      swipe: true,
      swipeToSlide: true,
      draggable: true,
      // fade: true,
      centerMode: true,
      centerPadding: '16.9444444%', // margin込み 14.0972222%、なし16.8055556%、Frameless 16.9444444%
      responsive: true,
      speed: 700,
      focusOnSelect: false,
      pauseOnHover: false,
      easing: 'swing',
      initialSlide: this.options["InitialSlide"],
      responsive: [{
        breakpoint: 900,
        settings: {
          centerPadding: '0'
        }
      },

      // MAX 1600
      {
        // (271.111111084/1600)
        breakpoint: 1600,
        settings: {
          centerPadding: '16.9444444%'
        }
      },
      {
        // (321.111111084/1700)
        breakpoint: 1700,
        settings: {
          centerPadding: '18.8888889%'
        }
      },
      {
        // (371.111111084/1800)
        breakpoint: 1800,
        settings: {
          centerPadding: '20.6172839%'
        }
      },
      {
        // (421.111111084/1900)
        breakpoint: 1900,
        settings: {
          centerPadding: '22.1637427%'
        }
      },
      {
        // (471.111111084/2000)
        breakpoint: 2000,
        settings: {
          centerPadding: '23.5555556%'
        }
      },
      {
        // (521.111111084/2100)
        breakpoint: 2100,
        settings: {
          centerPadding: '24.8148148%'
        }
      },
      {
        // (571.111111084/2200)
        breakpoint: 2200,
        settings: {
          centerPadding: '25.959596%'
        }
      },
      {
        // (621.111111084/2300)
        breakpoint: 2300,
        settings: {
          centerPadding: '27.0048309%'
        }
      },
      {
        // (671.111111084/2400)
        breakpoint: 2400,
        settings: {
          centerPadding: '27.962963%'
        }
      },
      {
        // (721.111111084/2500)
        breakpoint: 2500,
        settings: {
          centerPadding: '28.8444444%'
        }
      },
      {
        // (771.111111084/2600)
        breakpoint: 2600,
        settings: {
          centerPadding: '29.6581197%'
        }
      },
      {
        // (821.111111084/2700)
        breakpoint: 2700,
        settings: {
          centerPadding: '30.4115226%'
        }
      },
      {
        // (871.111111084/2800)
        breakpoint: 2800,
        settings: {
          centerPadding: '31.1111111%'
        }
      },
      {
        // (921.111111084/2900)
        breakpoint: 2900,
        settings: {
          centerPadding: '31.7624521%'
        }
      },
      {
        // (971.111111084/3000)
        breakpoint: 3000,
        settings: {
          centerPadding: '32.3703704%'
        }
      },
      {
        // (1021.111111084/3100)
        breakpoint: 3100,
        settings: {
          centerPadding: '32.9390681%'
        }
      },
      {
        // (1071.111111084/3200)
        breakpoint: 3200,
        settings: {
          centerPadding: '33.4722222%'
        }
      },

      // MAX 1440
      // {
      //   // (244/1440)
      //   breakpoint: 1440,
      //   settings: {
      //     centerPadding: '16.9444444%'
      //   }
      // },
      // {
      //   // (294/1540)
      //   breakpoint: 1540,
      //   settings: {
      //     centerPadding: '19.0909091%'
      //   }
      // },
      // {
      //   // (344/1640)
      //   breakpoint: 1640,
      //   settings: {
      //     centerPadding: '20.9756098%'
      //   }
      // },
      // {
      //   // (394/1740)
      //   breakpoint: 1740,
      //   settings: {
      //     centerPadding: '22.6436782%'
      //   }
      // },
      // {
      //   // (444/1840)
      //   breakpoint: 1840,
      //   settings: {
      //     centerPadding: '24.1304348%'
      //   }
      // },
      // {
      //   // (494/1940)
      //   breakpoint: 1940,
      //   settings: {
      //     centerPadding: '25.4639175%'
      //   }
      // },
      // {
      //   // (544/2040)
      //   breakpoint: 2040,
      //   settings: {
      //     centerPadding: '26.6666667%'
      //   }
      // },
      // {
      //   // (594/2140)
      //   breakpoint: 2140,
      //   settings: {
      //     centerPadding: '27.7570093%'
      //   }
      // },
      // {
      //   // (644/2240)
      //   breakpoint: 2240,
      //   settings: {
      //     centerPadding: '28.75%'
      //   }
      // },
      // {
      //   // (694/2340)
      //   breakpoint: 2340,
      //   settings: {
      //     centerPadding: '29.6581197%'
      //   }
      // },
      // {
      //   // (744/2440)
      //   breakpoint: 2440,
      //   settings: {
      //     centerPadding: '30.4918033%'
      //   }
      // },
      // {
      //   // (794/2540)
      //   breakpoint: 2540,
      //   settings: {
      //     centerPadding: '31.2598425%'
      //   }
      // },
      // {
      //   // (994/2940)
      //   breakpoint: 10000,
      //   settings: {
      //     centerPadding: '33.33333%'
      //   }
      // }

      // {
      //   // (282/1600)
      //   breakpoint: 1800,
      //   settings: {
      //     centerPadding: '17.625%'
      //   }
      // },
      // {
      //   // (682/2400)
      //   breakpoint: 2500,
      //   settings: {
      //     centerPadding: '28.4166667%'
      //   }
      // },
    ],
    })
  }

  setSliderSize() {
    setTimeout(() => {
      if(window.innerWidth > 900) {
        // (618/1440)
        // const slideHeight = this.element.clientWidth * .429166667
        // (475/1440)
        const slideHeight = this.element.clientWidth * .329861111
        TweenMax.set(this.slides, {
          css: {
            'height': slideHeight,
          }
        })
      }
      if(window.innerWidth > 900 && window.innerWidth <= 1440) {
        // (618/1440)
        // const slideHeight = this.element.clientWidth * .429166667
        // (475/1440)
        const slideHeight = this.element.clientWidth * .329861111
        TweenMax.set(this.slides, {
          css: {
            'height': slideHeight,
          }
        })
      }
      if(window.innerWidth > 1440 && window.innerWidth <= 1800) {
        // (618/1440)
        // const slideHeight = this.element.clientWidth * .38625
        // const slideHeight = this.element.clientWidth * .39625
        // (475/1800)
        const slideHeight = this.element.clientWidth * .263888889
        TweenMax.set(this.element, {
          css: {
            'height': slideHeight,
          }
        })
      }
      if(window.innerWidth > 1800 && window.innerWidth <= 2500) {
        // (618/2400)
        // const slideHeight = this.element.clientWidth * .2575
        // (475/2500)
        const slideHeight = this.element.clientWidth * .197916667
        TweenMax.set(this.element, {
          css: {
            'height': slideHeight,
          }
        })
      }
      if(window.innerWidth > 2500) {
        // (618/3100)
        // const slideHeight = this.element.clientWidth * .199354839
        // (475/3100)
        const slideHeight = this.element.clientWidth * .153225806
        TweenMax.set(this.element, {
          css: {
            'height': slideHeight,
          }
        })
      }
    },100)
  }

  // afterChange() {
  //   $(this.element).on('afterChange', (event, slick, currentSlide, nextSlide) => {
  //     this.frame = $(this.element).find('.slick-current')
  //     this.TL = new TimelineMax({ paused: true })
  //     this.TL.to(this.frame, 0.5, { webkitClipPath : 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' })
  //     this.TL.restart()
  //   })
  // }

  // beforeChange() {
  //   $(this.element).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
  //     this.frame = $(this.element).find('.slick-current')
  //     this.TL = new TimelineMax({ paused: true })
  //     this.TL.to(this.frame, 0.5, { webkitClipPath : 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)' })
  //     this.TL.restart()
  //     // console.log($(this).css('clip-path'))
  //   })
  // }
}
