export default class CommonModal {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.target = document.querySelectorAll(`[data-module-commonmodal-target="${this.options.target}"]`)[0];
    this.closeList = document.querySelectorAll(`[data-module-commonmodal-close="${this.options.target}"]`);
    this.closeNode = Array.prototype.slice.call(this.closeList, 0);
    this.init();
  }

  //initialize
  init() {
    this.eventBind();
  }

  //==============================
  //イベントバインド
  //==============================
  eventBind() {
    $(this.element).on('click', () => {
      this.modalOpen();
    });

    this.closeNode.forEach(element => {
      $(element).on('click', () => {
        this.modalClose();
      });
    });
  }

  //==============================
  //モーダル開く
  //==============================
  modalOpen() {
    $(this.target).fadeIn(200, 'swing');
    $(document).trigger('modalOpen')
    $(document.body).css('overflow', 'hidden')
  }

  //==============================
  //モーダル閉じる
  //==============================
  modalClose() {
    $(this.target).fadeOut(200, 'swing');
    $(document).trigger('modalClose')
    $(document.body).css('overflow-y', 'auto')
  }
}