export default class InsertModalDataSync {

  constructor(element, options) {
    this.filterData = Array.prototype.slice.call(element.querySelectorAll(options.filterDataEl), 0)
    this.syncFromCategories = Array.prototype.slice.call(element.querySelectorAll(options.syncFromCategoryEl), 0)
    this.syncToCategories = Array.prototype.slice.call(element.querySelectorAll(options.syncToCategoryEl), 0)
    this.syncFromBrands = Array.prototype.slice.call(element.querySelectorAll(options.syncFromBrandEl), 0)
    this.syncToBrands = Array.prototype.slice.call(element.querySelectorAll(options.syncToBrandEl), 0)
    this.syncFromPickies = Array.prototype.slice.call(element.querySelectorAll(options.syncFromPickyEl), 0)
    this.syncToPickies = Array.prototype.slice.call(element.querySelectorAll(options.syncToPickyEl), 0)
    this.keywordTexts = Array.prototype.slice.call(element.querySelectorAll(options.keywordTextEl), 0)
    this.checkBoxes = Array.prototype.slice.call(element.querySelectorAll(options.checkBoxEl), 0)
    this.numberTexts = Array.prototype.slice.call(element.querySelectorAll(options.numberTextEl), 0)
    this.resetBrandBtns = Array.prototype.slice.call(element.querySelectorAll(options.resetBrandBtnEl), 0)
    this.resetPickyBtns = Array.prototype.slice.call(element.querySelectorAll(options.resetPickyBtnEl), 0)
    this.resetAllBtns = Array.prototype.slice.call(element.querySelectorAll(options.resetAllBtnEl), 0)
    this.filterBtns = Array.prototype.slice.call(element.querySelectorAll(options.filterBtnEl), 0)

    // 検索時のデータ
    this.selectedData = { }

    this.init()
  }

  init() {

    const inputTexts = this.keywordTexts.concat(this.numberTexts)

    this.syncFromCategories.forEach(syncFromCategory => {
      syncFromCategory.addEventListener('click', this.handleSyncFromCategoryClick.bind(this))
    })

    this.syncFromBrands.forEach(syncFromBrand => {
      syncFromBrand.addEventListener('click', this.handleSyncFromBrandClick.bind(this))
    })

    this.syncFromPickies.forEach(syncFromPicky => {
      syncFromPicky.addEventListener('click', this.handleSyncFromPickyClick.bind(this))
    })

    this.keywordTexts.forEach(keywordText => {
      keywordText.addEventListener('change', this.handleKeywordTextChange.bind(this))
    })

    this.numberTexts.forEach(numberText => {
      numberText.addEventListener('change', this.handleNumberTextChange.bind(this))
    })

    this.resetBrandBtns.forEach(resetBrandBtn => {
      resetBrandBtn.addEventListener('click', this.handleResetBrandBtnClick.bind(this))
    })

    this.resetPickyBtns.forEach(resetPickyBtn => {
      resetPickyBtn.addEventListener('click', this.handleResetPickyBtnClick.bind(this))
    })

    this.resetAllBtns.forEach(resetAllBtn => {
      resetAllBtn.addEventListener('click', this.handleResetAllBtnClick.bind(this))
    })

    inputTexts.forEach(inputText => {
      inputText.addEventListener('focus', this.handleInputFocus.bind(this))
    })

    inputTexts.forEach(inputText => {
      inputText.addEventListener('blur', this.handleInputBlur.bind(this))
    })

  }

  btnActive() {
    this.filterBtns.forEach(filterBtn => filterBtn.disabled = false)
  }

  btnDisabled() {
    this.filterBtns.forEach(filterBtn => filterBtn.disabled = true)
  }

  changeBtnState() {
    let isInputed = false

    this.filterData.forEach( data => {
      if (data.value !== '') isInputed = true
    })

    isInputed ? this.btnActive() : this.btnDisabled()
  }

  formatShowData(selectAry) {
    let counter = 0
    let showStr = ''

    selectAry.forEach((itm, index) => {
      index === 0 ? showStr = itm : counter++
    })

    return selectAry.length > 1 ? `${showStr},他${counter}件` : `${showStr}`
  }

  getMetaViewport() {
    const metalist = document.getElementsByTagName('meta')

    for (let i = 0; i < metalist.length; i++) {
      const name = metalist[i].getAttribute('name')
      if (name && name.toLowerCase() === 'viewport') {
        return metalist[i]
      }
    }
  }

  handleSyncFromCategoryClick(e) {
    this.syncToCategories.forEach(syncToCategory => syncToCategory.value = e.currentTarget.value)
    this.selectedData['category'] = e.currentTarget.value

    this.changeBtnState()
  }

  handleSyncFromBrandClick() {
    const selected = []
    let selectedStr = ''
    let showStr = ''

    this.checkBoxes.forEach( checkBox => {
      if (checkBox.getAttribute('name') === 'brand') {
        if (checkBox.checked) selected.push(checkBox.value)
      }
    })

    selectedStr = selected.join(',')
    showStr = this.formatShowData(selected)

    this.selectedData['brand'] = selectedStr
    this.syncToBrands.forEach(syncToBrand => syncToBrand.value = showStr)
    
    this.changeBtnState()
  }

  handleSyncFromPickyClick() {
    const selected = []
    let selectedStr = ''
    let showStr = ''

    this.checkBoxes.forEach(checkBox => {
      if (checkBox.getAttribute('name') === 'picky') {
        if (checkBox.checked) selected.push(checkBox.value)
      }
    })

    selectedStr = selected.join(',')
    showStr = this.formatShowData(selected)

    this.selectedData['picky'] = selectedStr
    this.syncToPickies.forEach(syncToPicky => syncToPicky.value = showStr)

    this.changeBtnState()
  }

  handleKeywordTextChange(e) {
    this.selectedData['keyword'] = e.currentTarget.value

    this.changeBtnState()
  }

  handleNumberTextChange(e) {
    const name = e.currentTarget.name
    this.selectedData[name] = e.currentTarget.value

    // NOTE: /assets/js/src/module/FormatPrice.js のフォーマット処理が完了後に処理する。
    setTimeout(() => this.changeBtnState())
  }

  handleResetBrandBtnClick() {
    this.checkBoxes.forEach(checkBox => {
      if (checkBox.getAttribute('name') === 'brand') checkBox.checked = false
    })
  }

  handleResetPickyBtnClick() {
    this.checkBoxes.forEach(checkBox => {
      if (checkBox.getAttribute('name') === 'picky') checkBox.checked = false
    })
  }

  handleResetAllBtnClick() {
    // 選択済みデータ削除
    this.filterData.forEach(data => data.value = null)

    // チェックボックスのチェックを削除
    this.checkBoxes.forEach(checkBox => checkBox.checked = false)

    // ボタン非活性
    this.btnDisabled()

    // 検索データの削除
    this.selectedData = {}
  }

  handleInputFocus() {
    let viewport = this.getMetaViewport()

    const metaContent = viewport.getAttribute('content')
    const newMetaContent = metaContent + 'user-scalable=no'

    viewport.setAttribute('content', newMetaContent)
  }

  handleInputBlur() {
    let viewport = this.getMetaViewport()

    const metaContent = viewport.getAttribute('content')
    const newMetaContent = metaContent.replace('user-scalable=no', '')

    viewport.setAttribute('content', newMetaContent)
  }

}
