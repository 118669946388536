export default class Favorite {
  constructor(element) {
    this.element = element
    this.heart = $(this.element).find('.c-favorite-btn__img')
    this.playFlg = false
    this.init()
  }

  init() {
    const _this = this
    $(this.element).on('click', function(){_this.animate()})
  }

  animate() {
    if ($(this.heart).hasClass('play')){
      $(this.heart).removeClass('play')
      $(this.element).removeClass('play')
    } else {
      $(this.heart).addClass('play')
      $(this.element).addClass('play')
    }
  }
}
