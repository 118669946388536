import TweenMax from '../../libs/_TweenMax.min.js';

export default class Accordion {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.flg = false;
    this.trigger = this.element.querySelectorAll(`[data-module-accordion-trigger="${this.options.target}"]`)[0];
    this.target = this.element.querySelectorAll(`[data-module-accordion-target="${this.options.target}"]`)[0];
    this.targetInner = this.target.firstElementChild;
    console.log(this.targetInner)
    this.targetInnerHeight = this.targetInner.clientHeight;
    this.key = this.element.dataset.accordionGroup || null
    this.txtObj = this.element.querySelector(`[data-accordion-chngetxt="${this.options.target}"]`) || null
    this.allOpenBtn = document.querySelector(`[data-accordion-key="${this.key}"]`)
    this.init();
  }

  //initialize
  init() {
    this.eventBind();
    this.setAccordion();
  }

  //==============================
  //イベントバインド
  //==============================
  eventBind() {
    $(this.trigger).on('click', () => {
      this.switchAccordion();
    });
    if (this.allOpenBtn !== null) {
      this.allOpenBtn.addEventListener('click', () => {
        this.flagAct()
      })
    }
  }

  //==============================
  //アコーディオンの初期状態設定
  //高さが可変するターゲットをデフォルトでオープン状態にする場合は
  //js-default--openをターゲットに付与してください。
  //==============================

  setAccordion() {
    if (this.target.classList.contains('js-default--open')) {
      this.flg = true;
      this.target.classList.remove('js-default--open')
    } else {
      TweenMax.set(this.target, {
        height: 0,
        overflow: 'hidden',
      });
    }

    this.trigger.style.cursor = 'pointer';
  }

  //==============================
  //アコーディオン切り替え
  //==============================
  switchAccordion() {
    if (!this.flg) {
      this.openAccordion();
      this.switchClass(true);
      if (this.txtObj !== null) {
        this.changeTxt(true)

      }
    } else {
      this.closeAccordion();
      this.switchClass(false);
      if (this.txtObj !== null) {
        this.changeTxt(false)
      }
    }
  }


  //==============================
  //アコーディオン開く
  //==============================
  openAccordion() {
    TweenMax.to(this.target, 0.3, {
      height: this.targetInner.clientHeight + 'px',
      ease: Power1.easeOut,
      onComplete: () => {
        this.flg = true;
      }
    });
  }

  //==============================
  //アコーディオン閉じる
  //==============================
  closeAccordion() {
    TweenMax.to(this.target, 0.3, {
      height: 0,
      ease: Power1.easeOut,
      overflow: 'hidden',
      onComplete: () => {
        this.flg = false;
      }
    });
  }

  //==============================
  //classを切り替える
  //==============================
  switchClass(flg) {
    if (flg) {
      $(this.trigger).addClass('js-accordion-open');
    } else {
      $(this.trigger).removeClass('js-accordion-open');
    }
  }
  //==============================
  //テキスト変更があれば変更
  //==============================
  changeTxt(flg) {
    if (flg) {
      this.txtObj.textContent = '閉じる'
    } else {
      this.txtObj.textContent = 'もっと見る'
    }
  }

  //==============================
  //モーダルが全て開いているかしまっているかをチェック
  //==============================
  flagAct() {
    switch (this.allOpenBtn.dataset.accordionState) {
      case 'open':
        this.flg = false
        break;
      case 'close':
        this.flg = true
        break;
      default:
        break;
    }
  }
}