export default class TopContentsSlider {
  constructor(element, options){
    this.element = element
    this.options = options
    this.sliderElement = $(this.element).find('.p-top-contents__container__inner')
    this.sliderNext = $(this.element).find('.p-top-contents__arrow--right')
    this.sliderPrev = $(this.element).find('.p-top-contents__arrow--left')
    this.init()
  }
  init() {
    $(this.sliderElement).slick({
      centerMode: true,
      centerPadding: 0,
      dots: true,
      arrows: true,
      infinite: false,
      focusOnSelect: false,
      speed: 500,
      prevArrow: this.sliderPrev,
      nextArrow: this.sliderNext,
      // slidesToShow: 1,
      // initialSlide: initSlide,
      swipeToSlide: true,
      responsive: [{
        breakpoint: 900
        // settings: {
        //   centerMode: true
        // }
      }],
      // variableWidth: true,
      // dotsClass: 'p-top-spec-slick-dots'
    });
  }
}
