import TweenMax from '../../libs/_TweenMax.min.js';


export default class AllAccordion {
  constructor(element) {
    /**
     * ALLaccodion対象にdata-accordion-state = "初期値"を付与してください
     * 初期値は最初から全て開かれている場合の時は'open'閉じている時は'close'
     * @param {object} this.element - 全てのアコーディオンを制御するエレメント
     * @param {object} this.btnTxtElem - this.elem配下に入っている'a'タグ
     * @param {string} this.key - 全制御する対象のアコーディオンのkey this.elementにdata-accodion-keyを記述
     * @param {object} this.target -対象のアコーディオンnodeListで返ってくる
     * @param {string} this.OPEN - アコーディオン全体のステータス 全て開かれていれば 'open'
     * @param {string} this.CLOSE - アコーディオン全体のステータス 全て閉じていれば 'close'
     * @constructor
     */
    this.element = element;
    this.btnTxtElem = this.element.getElementsByTagName('a')[0]
    this.key = this.element.dataset.accordionKey
    this.target = document.querySelectorAll(`[data-accordion-group='${this.key}']`)

    this.OPEN = 'open'
    this.CLOSE = 'close'
    this.init();
  }

  /**
   * init
   * @param {object} obj - アコーディオンで表示・非表示される要素
   * @param {object} trigger - 対象のアコーディオン
   * @param {number} height - objの高さ
   */
  init() {
    this.target = Array.from(this.target).map(item => {
      let pack = {}
      pack['obj'] = item.querySelector('[data-module-accordion-target]')
      pack['trigger'] = item.querySelector('[data-module-accordion-trigger]')
      pack['height'] = pack['obj'].firstElementChild.clientHeight
      return pack
    })
    this.eventBinds()
  }

  /**
   * eventBinds
   * イベントハンドラ
   * @param {string} order - アコーディオンが全て開かれた場合は'open' 全て閉じた場合は'close'が入る
   */
  eventBinds() {
    this.element.addEventListener('click', () => {
      const order = this.element.dataset.accordionState
      switch (order) {
        case this.OPEN:
          this.closeAccodion()
          break;
        case this.CLOSE:
          this.openAccordion()
        break;
        default:
          break;
      }
    })
    this.target.map(item => {
      item.trigger.addEventListener('click', () => {
        this.judge()
      })
    })
  }

  /**
   * judge
   * アコーディオンが全て開かれているか全て閉じているかを判別
   * 全て開かれている場合は 'open'
   * 全て閉じている場合は'close'に替える
   */
  judge() {
    let openElem = this.target.map(item => {
      if (item.trigger.classList.contains('js-accordion-open')) {
        return item
      }
    })
      .filter(item => item)
    if (openElem.length === this.target.length) {
      this.chngState(this.OPEN)

    } else if (openElem.length === 0) {
      this.chngState(this.CLOSE)
    }
  }

  /**
   * openAccordion
   * アコーディオンを開く際の処理
   * 処理実行後ステータスを'open'に変える
   */
  openAccordion() {
    this.target.map(item => {
      TweenMax.to(item.obj, 0.5, {
        height: item.obj.firstElementChild.clientHeight + 'px',
      });
      if (!item.trigger.classList.contains('js-accordion-open')) {
        item.trigger.classList.add('js-accordion-open')
      }
    })
    this.chngState(this.OPEN)
  }

  /**
   * closeAccordion
   * アコーディオンを閉じる際の処理
   * 処理実行後ステータスを'close'に変える
   * カラーが選択されているグループ（has-active-item）は閉じなくなる
   */
  closeAccodion() {
    this.target.map(item => {
      if(!item.trigger.classList.contains('has-active-item')) {
        TweenMax.to(item.obj, 0.5, {
          height: 0,
        });
        if (item.trigger.classList.contains('js-accordion-open')) {
          item.trigger.classList.remove('js-accordion-open')
        }
      }
    })
    this.chngState(this.CLOSE)
  }

  /**
   * chngState
   * orderに入るステータスに応じてボタンの文言を変更および
   * ステータスを変える
   * @param {string} order - 'close'もしくは'open'が入る
   */
  chngState(order) {
    this.element.dataset.accordionState = order
    switch (order) {
      case this.OPEN:
        this.btnTxtElem.textContent = '全てを閉じる'
        break;
      case this.CLOSE:
        this.btnTxtElem.textContent = '全てを表示'
        break;
      default:
        break;
    }
  }
}