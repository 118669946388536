import Module from './module'
// import PathChange from './module/PathChange.js'

document.addEventListener('DOMContentLoaded', ()=>{
  // TweenMax.set(document.body, { opacity: 0 })
  new Module()
  // new PathChange()

  // karteCheck()

  // setTimeout(() => {
  //   window.localStorage.setItem('karteLoad', 'done');
  // },500)

  // Clear Karte Flag
  window.localStorage.removeItem('serendipity1');
  window.localStorage.removeItem('serendipity2');
  window.localStorage.removeItem('spcampaign');
  window.localStorage.removeItem('ranking1');
  window.localStorage.removeItem('ranking2');
  window.localStorage.removeItem('ranking3');
  window.localStorage.removeItem('ranking4');
  window.localStorage.removeItem('seen_together');
  window.localStorage.removeItem('seen_lately');
  window.localStorage.removeItem('brand_recommended');
  window.localStorage.removeItem('recommended');
  window.localStorage.removeItem('recommended_article');

  // Karte Flag
  // window.localStorage.setItem('serendipity1', 'done');
  // window.localStorage.setItem('serendipity2', 'done');
  // window.localStorage.setItem('spcampaign', 'done');
  // window.localStorage.setItem('ranking1', 'done');
  // window.localStorage.setItem('ranking2', 'done');
  // window.localStorage.setItem('ranking3', 'done');
  // window.localStorage.setItem('ranking4', 'done');
  // window.localStorage.setItem('seen_together', 'done');
  // window.localStorage.setItem('seen_lately', 'done');
  // window.localStorage.setItem('brand_recommended', 'done');
  // window.localStorage.setItem('recommended', 'done');
})

const karteCheck = function karteCheck() {
  setTimeout(() => {
    const stat = window.localStorage.getItem('karteLoad')
    if (stat === null) {
      karteCheck()
    } else {
      runModule()
    }
  },100)
}

function runModule() {
  new Module()
  TweenMax.to(document.body, 1, { opacity: 1, delay: 1 })
  window.localStorage.removeItem('karteLoad');
}