import TweenMax from '../../libs/_TweenMax.min.js'

export default class HeaderShrink {
  constructor(element) {
    this.element = element
    this.shrinkFlg = false
    this.init()
  }

  init() {
    window.addEventListener('scroll', () => this.shrink())
  }

  shrink() {
    if (!this.shrinkFlg) {
      if (window.pageYOffset > 0) {
        this.shrinkFlg = true
        $('.l-header').addClass('shrink')
      }
    }
    if (this.shrinkFlg) {
      if (window.pageYOffset == 0) {
        this.shrinkFlg = false
        $('.l-header').removeClass('shrink')
      }
    }
  }
}
