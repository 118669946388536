import TweenMax from '../../libs/_TweenMax.min.js'
import Fade from './Fade'
import TruncateText from './TruncateText'
import Gradient from './Gradient'

export default class TopProductsJs {
  constructor(element, options){
    this.element = element
    this.options = options
    this.karteFlag = this.options.karteFlag
    this.timeoutTimer = 5000
    this.init()
  }
  init() {
    this.watchFlag()
  }

  watchFlag() {
    const _this = this
    this.karteTimeout()
    const karteCheck = function karteCheck() {
      setTimeout(() => {
        const stat = window.localStorage.getItem(_this.karteFlag)
        if (stat === null) {
          karteCheck()
        } else {
          // console.log('clear karteTimer')
          clearTimeout(_this.karteTimer)
          _this.bindEvents()
        }
      },100)
    }
    karteCheck()
  }

  karteTimeout() {
    this.karteTimer = setTimeout(() => {
      this.bindEvents()
    }, this.timeoutTimer)
  }

  bindEvents() {
    const _this = this
    this.productImages = $(this.element).find('.p-top-products__item__thumb').each(function(){
      const img = $(this).find('img')
      const type = $(this).attr('data-type')
      $(this).hover(function() { _this.Shrink(this, img, type) }, function() { _this.Expand(this, img, type) })
    })

    this.productItems = $(this.element).find('.p-top-products__item').each(function(){
      if ($(this).hasClass('no-fade')) {
        _this.renew(this)
      } else {
        new Fade(this)
      }
    })
  }
  
  Shrink(el,img,type) {
    TweenMax.to(el, 1.3, {
      css: {
        "transform": "scale(0.93)" 
      },
      ease: Elastic.easeOut.config(1, 0.3)
    })
    if (type == 1) {
      TweenMax.to(img, 1.3, {
        css: {
          "transform": "translate(-50%,-50%) scale(1.03)" 
        },
        ease: Elastic.easeOut.config(1, 0.3)
      })
    }
  }

  Expand(el,img,type) {
    // TweenMax.to(el, 1.3, { scale: 1.00001, ease: Elastic.easeOut.config(1, 0.3) })
    // TweenMax.to(img, 1.3, { scale: 1.00001, ease: Elastic.easeOut.config(1, 0.3) })
    TweenMax.to(el, 1.3, {
      css: {
        "transform": "scale(1)" 
      },
      ease: Elastic.easeOut.config(1, 0.3)
    })
    if (type == 1) {
      TweenMax.to(img, 1.3, { 
        css: {
          "transform": "translate(-50%,-50%) scale(1)" 
        },
        ease: Elastic.easeOut.config(1, 0.3)
      })
    }
  }

  renew(elm) {
    // console.log('renew init')
    Array.prototype.forEach.call(elm.querySelectorAll('[data-module]'), element => {
      const keys = element.getAttribute('data-module').split(/\s+/)
      const opts = element.getAttribute('data-options') || null
      keys.forEach(key => {
        const Module = require(`./${key.charAt(0).toUpperCase() + key.slice(1)}`).default
        const options = opts ? (keys.length > 1) ? JSON.parse(opts)[key] : JSON.parse(opts) : {}
        if (Module !== void 0)
          return new Module(element, options)
      })
    })
  }
}
