import TweenMax from '../../libs/_TweenMax.min.js';

export default class AccordionGradual {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.flg = false;
    this.trigger = this.element.querySelectorAll(`[data-module-accordion-gradual-trigger="${this.options.target}"]`)[0];
    this.targets = this.element.querySelectorAll(`[data-module-accordion-gradual-target="${this.options.target}"]`);
    this.phase = 0
    this.target = this.targets[this.phase];
    this.key = this.element.dataset.accordionGroup || null
    this.txtObj = this.element.querySelector(`[data-accordion-gradual-chngetxt="${this.options.target}"]`) || null
    this.allOpenBtn = document.querySelector(`[data-accordion-gradual-key="${this.key}"]`)
    this.phaseLimit = Math.min(this.targets.length, this.options.limit)
    this.init();
  }

  //initialize
  init() {
    this.eventBind();
    this.setAccordion();
  }

  //==============================
  //イベントバインド
  //==============================
  eventBind() {
    $(this.trigger).on('click', () => {
      this.switchAccordion();
    });
  }

  //==============================
  //アコーディオンの初期状態設定
  //==============================

  setAccordion() {
    for (let i = 0; i < this.targets.length; i++) {
      TweenMax.set(this.targets[i], {
        height: 0,
        overflow: 'hidden',
      });
    }
    this.trigger.style.cursor = 'pointer';
  }

  //==============================
  //アコーディオン切り替え
  //==============================
  switchAccordion() {
    this.target = this.targets[this.phase];
    if (!this.flg) {
      this.openAccordion();
    } else {
      this.closeAccordion();
    }
  }


  //==============================
  //アコーディオン開く
  //==============================
  openAccordion() {
    TweenMax.to(this.target, 0.3, {
      height: this.target.firstElementChild.clientHeight + 'px',
      ease: Power1.easeOut,
      onComplete: () => {
        this.phase++;
        if (this.phase >= this.phaseLimit) {
          this.flg = true;
        }
        this.changeState(this.flg);
      }
    });
  }

  //==============================
  //アコーディオン閉じる
  //==============================
  closeAccordion() {
    for (let i = 0; i < this.targets.length; i++) {
      TweenMax.to(this.targets[i], 0.3, {
        height: 0,
        ease: Power1.easeOut,
        overflow: 'hidden',
        onComplete: () => {
          this.phase = 0;
          this.flg = false;
          this.changeState(this.flg);
        }
      });
    }
  }

  //==============================
  //状態の切り替え
  //==============================
  changeState(flg) {
    this.switchClass(flg);
    if (this.txtObj !== null) {
      this.changeTxt(flg)
    }
  }

  //==============================
  //classを切り替える
  //==============================
  switchClass(flg) {
    if (flg) {
      $(this.trigger).addClass('js-accordion-open');
    } else {
      $(this.trigger).removeClass('js-accordion-open');
    }
  }
  //==============================
  //テキスト変更があれば変更
  //==============================
  changeTxt(flg) {
    if (flg) {
      this.txtObj.textContent = '閉じる'
    } else {
      this.txtObj.textContent = 'もっと見る'
    }
  }
}