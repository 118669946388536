import TweenMax from '../../libs/_TweenMax.min.js';

export default class HamburgerMenu {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.target = document.querySelectorAll('[data-module-target="HamburgerMenu"]')[0];
    this.targetInner = document.querySelectorAll('[data-module-target-inner="HamburgerMenu"]')[0];
    this.closeElement = document.querySelectorAll('[data-module-close="HamburgerMenu"]');
    this.closeButton = document.querySelectorAll('[data-module-fade="HamburgerMenu"]');
    this.closeElementNode = Array.prototype.slice.call(this.closeElement, 0);
    this.init();
  }

  //initialize
  init() {
    this.eventBind();
  }

  //==============================
  //イベントバインド
  //==============================
  eventBind() {
    $(this.element).on('click', () => {
      this.hamburgerSwitch(true);
    });

    this.closeElementNode.forEach(element => {
      $(element).on('click', () => {
        this.hamburgerSwitch(false);
      });
    });
  }

  //==============================
  //ハンバーガーメニュー開閉
  //==============================
  hamburgerSwitch(flg) {
    if (flg) {
      this.hamburgerShow();
      $(document.body).css('overflow', 'hidden')
    } else {
      this.hamburgerHide();
      $(document.body).css('overflow-y', 'auto')
    }
  }

  //==============================
  //ハンバーガーメニュー開閉
  //==============================
  hamburgerShow() {
    $(this.target).fadeIn(300, 'swing');
    TweenMax.to(this.targetInner, 0.3, {
      right: 0,
      ease: Power1.easeOut
    })
    TweenMax.to(this.closeButton, 0.3, {
      opacity: 1,
      delay: 0.3
    })
  }

  //==============================
  //ハンバーガーメニュー開閉
  //==============================
  hamburgerHide() {
    TweenMax.to(this.targetInner, 0.4, {
      right: '-60%',
      ease: Power1.easeOut
    })
    TweenMax.to(this.closeButton, 0.3, {
      opacity: 0,
    })
    $(this.target).fadeOut(100, 'swing');
  }
}