import TweenMax from '../../libs/_TweenMax.min.js';

export default class YmkPager {
  constructor(element, options) {
    /**
     * 親要素には以下の要素を指定します。
     * data-module="YmkPager"
     * data-options='{ "target":"group-name", "default":1, "trigger":"#YMK-module-iframe" }'
     * 
     * ターゲットには以下の要素を指定します。
     * data-module-ymkpager-target="group-name"
     * data-module-ymkpager-page="1"
     * を指定します
     * this.page と data-module-ymkpager-page が一致しないものは全てhideになります。
     * 
     * @param {object} this.element - 全てのコンテンツ切り替えを制御するエレメント
     * @param {string} this.options.trigger - 切り替え対象のエレメント（iframeを想定）
     * @param {string} this.options.target - コンテンツ切り替えを制御するグループ
     * @param {number} this.options.default - デフォルトで表示されているページの設定
     * @param {object} this.area - 切り替え対象のnodeList
     * @param {object} this.areaHeight - area の Height
     * @param {object} this.targets - 切り替え対象のnodeList
     * @param {number} this.page - 現在のページ
     * @constructor
     */
    this.element = element;
    this.options = options;
    this.area = this.element.querySelectorAll(`[data-module-ymkpager-area="${this.options.target}"]`)[0];
    this.areaHeight = 0;
    this.targets = this.element.querySelectorAll(`[data-module-ymkpager-target="${this.options.target}"]`);
    this.page = this.options.default || 1;
    this.init();
  }

  //initialize
  init() {
    this.eventBindStart();
    this.eventBindTrigger();
  }

  //==============================
  //イベントバインド
  //==============================
  eventBindStart() {
    // JSで後からDOMが生成されるため、500ms間隔でチェックしてDOMが生成されたらイベントバインド
    
    setTimeout(() => {
      if ($(`[data-module-ymkpager-start="${this.options.target}"]`).length) {
        $(`[data-module-ymkpager-start="${this.options.target}"]`).on('click', (e) => {
          this.setDefault();
        });
      } else {
        this.eventBindStart();
      }      
    }, 500)
  }

  eventBindTrigger() {
    // JSで後からDOMが生成されるため、500ms間隔でチェックしてDOMが生成されたらイベントバインド
    
    setTimeout(() => {
      if ($(`${this.options.trigger}`).contents().find("body").length) {
        $(`${this.options.trigger}`).contents().find("body").on('click', (e) => {
          this.switchContents(e.currentTarget);
        });
      } else {
        this.eventBindTrigger();
      }
    }, 500)
  }

  setDefault() {
    setTimeout(() => {
      if (this.targets[1].clientHeight) {
        // this.page = 1;
        this.areaHeight = 0;
        for (let i = 0; i < this.targets.length; i++) {
          let target = this.targets[i];
        
          if (this.targets[i].dataset.moduleYmkpagerPage == this.page) {
            target.style.display = 'block';
            target.style.opacity = 1;
          }
          if (this.areaHeight < target.clientHeight) {
            this.areaHeight = target.clientHeight + 'px'
          }

        }
        this.area.style.height = this.areaHeight;
      } else {
        this.setDefault();
      }
    }, 100)
  }

  //==============================
  //ページ切り替え
  //==============================
  changeContents() {
    for (let i = 0; i < this.targets.length; i++) {
      let target = this.targets[i];
      if (this.targets[i].dataset.moduleYmkpagerPage == this.page) {
        target.style.display = 'block';
        TweenMax.to(target, 0.3, {
          opacity: 1,
          ease: Power1.easeOut,
        });
    
      } else {
        TweenMax.to(target, 0.3, {
          opacity: 0,
          ease: Power1.easeOut,
        });
        setTimeout(() => {
          target.style.display = 'none';
          this.areaHeight = 'auto';
          this.area.style.height = this.areaHeight;
        }, 300);
      }
    }
  }

  //==============================
  //クリックイベント（ページ切り替え判定）
  //==============================
  switchContents() {
    if (this.page < this.targets.length) {
      this.page++;
    }
    this.changeContents()
  }
}