export default class KeyVisual {
  constructor(element, options){
    this.element = element
    this.options = options
    // this.init()
  }
  init() {
    this.setKvSize()
    window.addEventListener('resize', this.setKvSize.bind(this))
  }
  setKvSize() {
    setTimeout(() => {
      if(window.innerWidth > 900) {
        // (618/1440)
        // const slideHeight = this.element.clientWidth * .429166667
        // (475/1440)
        const slideHeight = this.element.clientWidth * .329861111
        TweenMax.set(this.element, {
          css: {
            'height': slideHeight,
          }
        })
      }
      if(window.innerWidth > 900 && window.innerWidth <= 1440) {
        // (618/1440)
        // const slideHeight = this.element.clientWidth * .429166667
        // (475/1440)
        const slideHeight = this.element.clientWidth * .329861111
        TweenMax.set(this.slides, {
          css: {
            'height': slideHeight,
          }
        })
      }
      if(window.innerWidth > 1440 && window.innerWidth <= 1800) {
        // (618/1440)
        // const slideHeight = this.element.clientWidth * .38625
        // const slideHeight = this.element.clientWidth * .39625
        // (475/1800)
        const slideHeight = this.element.clientWidth * .263888889
        TweenMax.set(this.element, {
          css: {
            'height': slideHeight,
          }
        })
      }
      if(window.innerWidth > 1800 && window.innerWidth <= 2500) {
        // (618/2400)
        // const slideHeight = this.element.clientWidth * .2575
        // (475/2500)
        const slideHeight = this.element.clientWidth * .197916667
        TweenMax.set(this.element, {
          css: {
            'height': slideHeight,
          }
        })
      }
      if(window.innerWidth > 2500) {
        // (618/3100)
        // const slideHeight = this.element.clientWidth * .199354839
        // (475/3100)
        const slideHeight = this.element.clientWidth * .153225806
        TweenMax.set(this.element, {
          css: {
            'height': slideHeight,
          }
        })
      }
    },100)
  }
}
