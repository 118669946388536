export default class CommonModal {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.init();
  }

  init() {
    $(document).on('modalOpen', () => this.set())
    $(document).on('modalClose', () => this.destroy())
  }

  set() {
    $(this.element).slick({
      adaptiveHeight: this.options.adaptiveHeight || false,
      autoplay: this.options.autoplay || false,
      autoplaySpeed: this.options.autoplaySpeed || 3000,
      arrows: this.options.arrows || true,
      prevArrow: document.querySelectorAll(this.options.slidePrev),
      nextArrow: document.querySelectorAll(this.options.slideNext),
      centerMode: this.options.centerMode || false,
      centerPadding: this.options.centerPadding || '50px',
      cssEase: this.options.cssEase || 'ease',
      dots: this.options.dots || false,
      draggable: this.options.draggable || false,
      infinite: this.options.infinite || false,
      initialSlide: this.options.initialSlide || 0,
      slidesToScroll: this.options.slidesToScroll || 1,
      speed: this.options.speed || 300,
      swipe: this.options.swipe || true,
      variableWidth: this.options.variableWidth || false,
    })
  }

  destroy() {
    $(this.element).slick('unslick')
  }


}