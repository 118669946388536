import TweenMax from '../../libs/_TweenMax.min.js'
export default class MyPageTabs {
  constructor(element, options) {
    this.element = element
    this.options = options
    this.tabs = this.element.querySelectorAll('[data-module-tab]');
    this.target = this.element.querySelectorAll('[data-module-target]');
    this.init()
  }

  //initialize
  init() {
    this.eventBind();
  }

  //イベントバインド
  eventBind() {
    const tabsAry = Array.prototype.slice.call(this.tabs, 0);
    tabsAry.forEach(element => {
      element.addEventListener('click', () => {
        const target = element.getAttribute('data-module-tab');
        this.changeTab(target);
        this.activeButton(element, tabsAry);
      });
    });
  }

  //タブチェンジ
  changeTab(target) {
    const targetAry = Array.prototype.slice.call(this.target, 0);
    const changeTarget = this.element.querySelectorAll(`[data-module-target=${target}]`)[0];
    targetAry.forEach(element => {
      element.style.display = 'none';
      // $(element).fadeOut();
    });
    // changeTarget.style.display = 'block';
    $(changeTarget).fadeIn();
  }

  //ボタンアクティブ化
  activeButton(target, tabsAry) {
    tabsAry.forEach(element => {
      element.classList.remove('active');
    });
    target.classList.add('active');
  }
}