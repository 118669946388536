import TweenMax from '../../libs/_TweenMax.min.js';

export default class Pager {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.itemLength = $(this.element).find('li').length;
    this.pagerSetting = {
      pageIndex: 1,
      view: Number(this.options.view),
      maxPage: Math.ceil(this.itemLength / this.options.view),
      target: this.options.target
    }
    this.position = $(this.element).offset().top
    console.log(this.position)
    this.pager = document.querySelectorAll(`[data-module-pager="${this.pagerSetting.target}"]`)[0];
    this.nextArrow = this.pager.querySelectorAll('[data-module-pager-arrow="next"]')[0];
    this.sortAfter = false;
    this.init();
  }

  //initialize
  init() {
    this.eventBind();
  }

  //==============================
  //セットパラメータ
  //==============================
  setParameters(view) {
    const itemAry = []
    $(this.element).find('li').each((index, element) => {
      if ($(element).hasClass('is-sort-unmatch') !== true) {
        itemAry.push(element);
      }
    })
    this.itemLength = itemAry.length;
    this.pagerSetting = {
      pageIndex: 1,
      view: Number(view),
      maxPage: Math.ceil(this.itemLength / view),
      target: this.options.target
    }
    this.pager = document.querySelectorAll(`[data-module-pager="${this.pagerSetting.target}"]`)[0];
    this.sortAfter = true;
  }

  //==============================
  //イベントバインド
  //==============================
  eventBind() {
    //ページ読み込み時
    $(window).on('load', () => {
      this.showItem();
      this.pagination();
    });

    $(document).on('afterSort', (event, afterSort, view) => {
      this.setParameters(view);
      this.showItem(afterSort);
      this.pagination(afterSort);
    });

    //番号押下時
    $(this.pager).on('click', '[data-module-pager-item]', (event) => {
      const target = event.target;
      const pagerNum = target.getAttribute('data-module-pager-item')
      this.swichPage(pagerNum)
      this.pagination();
    });

    //戻るボタン
    $(this.pager).find('[data-module-pager-arrow="prev"]').on('click', () => {
      console.log("一つ戻る");
      this.pagerSetting.pageIndex -= 1;
      this.swichPage(this.pagerSetting.pageIndex);
      this.pagination();
    })

    //進むボタン
    $(this.pager).find('[data-module-pager-arrow="next"]').on('click', () => {
      console.log("一つ進む");
      this.pagerSetting.pageIndex += 1;;
      this.swichPage(this.pagerSetting.pageIndex);
      this.pagination();
    })
  }

  //==============================
  //ページ切り替え
  //==============================
  swichPage(pagerNum) {
    $(window).scrollTop(this.position - 100);

    if (this.sortAfter) {
      this.pagerSetting.pageIndex = Number(pagerNum);
      $(this.element).find('li').each((index, element) => {
        $(element).css('display', 'none');
        this.showItem(true);
      });
    } else {
      this.pagerSetting.pageIndex = Number(pagerNum);
      $(this.element).find('li').each((index, element) => {
        $(element).css('display', 'none');
        this.showItem();
      });
    }
  }

  //==============================
  //アイテム表示
  //==============================
  showItem(afterSort) {
    const targetRange = this.pagerSetting.pageIndex * this.pagerSetting.view;
    if (afterSort) {
      this.afterSortShowItemSelect(targetRange)
    } else {
      this.showItemSelect(targetRange);
      // this.settingHeight()
    }
  }

  //==============================
  //アイテム表示
  //==============================
  settingHeight() {
    const height = $(this.element).outerHeight()
    $(this.element).css('min-height', height)
  }

  //==============================
  //表示するアイテムを選定
  //==============================
  afterSortShowItemSelect(targetRange) {
    const ary = []
    $.when(
      $(this.element).find('li').each((sortIndex, sortElement) => {
        if ($(sortElement).hasClass('is-sort-unmatch') !== true) {
          ary.push(sortElement);
        }
      })
    ).done(() => {
      for (let i = 0; i < ary.length; i++) {
        const element = ary[i];
        if (i + 1 <= targetRange && i + 1 > targetRange - this.pagerSetting.view) {
          $(element).css('display', 'block');
          TweenMax.to(element, 0.5, {
            y: '0',
            opacity: '1'
          })
        }
      }
    });
  }

  //==============================
  //表示するアイテムを選定
  //==============================
  showItemSelect(targetRange) {
    if (this.sortAfter) return false;
    $(this.element).find('li').each((index, element) => {
      if (index + 1 <= targetRange && index + 1 > targetRange - this.pagerSetting.view) {
        $(element).css('display', 'block');
      }
    });
  }

  //==============================
  //ページャー
  //==============================
  pagination() {
    console.log("----------------\nページ番号：", this.pagerSetting.pageIndex, "\n----------------");

    $(this.pager).find('a').remove();
    if (this.pagerSetting.pageIndex <= this.pagerSetting.maxPage && this.pagerSetting.maxPage <= 3) {
      //総ページ数が３ページ以下のとき
      for (let i = 1; i < this.pagerSetting.maxPage + 1; i++) {
        this.setButton(i);
      }
    } else if (this.pagerSetting.pageIndex <= 2) {
      //現在のページが2ページ以下の時ikanotoki
      for (let i = 1; i < 4; i++) {
        this.setButton(i);
      }
      this.ellipsisAdd();
      this.setButton(this.pagerSetting.maxPage);
    } else if (this.pagerSetting.pageIndex > this.pagerSetting.maxPage - 2) {
      //現在のページが最大ページ数から2引いたより大きい場合
      this.setButton(1);
      this.ellipsisAdd();
      for (let i = this.pagerSetting.maxPage - 2; i < this.pagerSetting.maxPage + 1; i++) {
        this.setButton(i);
      }
    } else {
      //上記のいずれにも該当しない場合
      this.setButton(1);
      this.ellipsisAdd();
      for (let i = this.pagerSetting.pageIndex - 1; i < this.pagerSetting.pageIndex + 3 - 1; i++) {
        this.setButton(i);
      }
      this.ellipsisAdd();
      this.setButton(this.pagerSetting.maxPage);
    }

    this.pagerArrow();
    this.activePager();
  }

  //==============================
  //省略記号追加
  //==============================
  ellipsisAdd() {
    const ellipsis = document.createElement('a');
    ellipsis.classList.add('c-pager__ellipsis');
    ellipsis.textContent = '…';
    // $(this.pager).append(ellipsis);
    $(this.nextArrow).before(ellipsis);
  }
  //==============================
  //ページャーボタン生成
  //==============================

  setButton(i) {
    const button = document.createElement('a');
    button.classList.add('c-pager__button');
    button.classList.add(`pager-${i}`);
    button.setAttribute('href', 'javascript:void(0)');
    button.setAttribute('data-module-pager-item', `${i}`);
    button.textContent = `${i}`;
    if (i === this.pagerSetting.maxPage) {
      button.classList.add('is-last');
    }
    // $(this.pager).append(button);
    $(this.nextArrow).before(button);
  }

  //==============================
  //ページャーの矢印
  //==============================
  pagerArrow() {
    if (this.pagerSetting.maxPage === 1) {
      $(this.pager).find('[data-module-pager-arrow]').css('display', 'none');
    } else if (this.pagerSetting.pageIndex === 1) {
      $(this.pager).find('[data-module-pager-arrow="prev"]').css('display', 'none');
      $(this.pager).find('[data-module-pager-arrow="next"]').css('display', 'block');
    } else if (this.pagerSetting.pageIndex === this.pagerSetting.maxPage) {
      $(this.pager).find('[data-module-pager-arrow="prev"]').css('display', 'block');
      $(this.pager).find('[data-module-pager-arrow="next"]').css('display', 'none');
    } else {
      $(this.pager).find('[data-module-pager-arrow]').css('display', 'block');
    }
  }

  //==============================
  //アクティブボタンを切り替える
  //==============================
  activePager() {
    const targetNode = this.pager.querySelectorAll('[data-module-pager-item]');
    const target = Array.prototype.slice.call(targetNode, 0);
    target.forEach(element => {
      $(element).removeClass('active');
      const num = element.getAttribute('data-module-pager-item');
      if (this.pagerSetting.pageIndex === Number(num)) {
        $(element).addClass('active');
      }
    });
  }
}