import TweenMax from '../../libs/_TweenMax.min.js';

export default class ProductSort {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.flg = false;
    this.sortArray = [];
    this.init();
  }

  //initialize
  init() {
    this.eventBind();
  }

  //==============================
  //イベントバインド
  //==============================
  eventBind() {
    $(this.element).on('click', () => {
      this.checkRadio();
    })
  }

  //==============================
  //ラジオボタンをチェックする
  //==============================
  checkRadio() {
    const targetList = document.querySelectorAll('.radio01-input');
    const target = Array.prototype.slice.call(targetList, 0);
    this.sortArray = [];

    target.forEach(element => {
      if (element.checked) {
        const sortTarget = element.getAttribute('data-module-sort-num');
        this.sortArray.push(sortTarget);
      }
    });
    this.sortAction();
  }

  //==============================
  //マッチする商品でソートする
  //==============================
  sortAction() {
    const targetList = document.querySelectorAll('[data-module-sort-target]');
    const target = Array.prototype.slice.call(targetList, 0);

    target.forEach(element => {
      TweenMax.to(element, 0.5, {
        y: '-10px',
        opacity: '0',
        onComplete: () => {
          this.classSwitch(element);
          setTimeout(() => {
            $(document).trigger('afterSort', [true, 20])
          }, 500);
        }
      });
    });
  }
  classSwitch(element) {
    const id = element.getAttribute('data-module-sort-target');
    console.log("id", id);
    console.log(this.sortArray.indexOf(id) == -1);
    if (this.sortArray.indexOf(id) == -1) {
      //- 配列に一致するものがない
      $(element).addClass('is-sort-unmatch');
    } else {
      //- 配列に一致するものがある
      $(element).removeClass('is-sort-unmatch');
    }
  }
}