import TweenMax from '../../libs/_TweenMax.min.js'

export default class Loading {
  constructor(element, options){
    this.element = element
    this.bg = $(this.element).find('.c-loading__bg')
    this.options = options
    this.init()
  }
  init() {
    this.animate()
  }

  animate() {
    TweenMax.to(this.element, 1, { opacity: 0, delay: 0.5, onComplete: () => this.finish() })
  }

  finish() {
    TweenMax.set(this.element, {
      css: {
        'z-index': -10,
        'display': 'none'
      }
    })
  }
}
