import { TweenMax } from "gsap";

export default class TopFixedBg {
  constructor(element, options){
    this.element = element
    this.options = options
    this.flag = false
    this.init()
  }
  init() {
    this.className = $(this.element).attr('class')
    this.bg = $(`.${ this.className+'__background'}`)
    window.addEventListener('scroll', () => this.bgCheck())
  }

  bgCheck() {
    this.topBorder = this.element.getBoundingClientRect().top
    // const topBorder = $(this.element).scrollTop()
    const botBorder = this.element.getBoundingClientRect().bottom
    if(this.topBorder < window.innerHeight+50) {
      if(!this.flag){
        this.flag = true
        this.bg.css('opacity', 1)
        // console.log(this.bg)
      }
    }
    if(this.topBorder > window.innerHeight+50) {
      if(this.flag){
        this.flag = false
        this.bg.css('opacity', 0)
      }
    }
  }
}
