
import TweenMax from '../../libs/_TweenMax.min.js';

export default class RefineSearch {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.target = document.querySelectorAll(`[data-module-refine-target="${this.options.target}"]`)[0];
    this.init();
  }

  //initialize
  init() {
    this.eventBind();
  }

  //==============================
  //イベントバインド
  //==============================
  eventBind() {
    $(this.element).on('click', () => {
      this.productSearch();
    })
  }


  //==============================
  //商品検索
  //==============================
  productSearch() {
    $(this.target).find('li').each((index, element) => {
      TweenMax.to(element, 0.5, {
        y: '-10%',
        opacity: '0',
        onComplete: () => {
          this.matchShow();
        }
      })
    });
  }


  //==============================
  //マッチした要素をだす
  //==============================
  matchShow() {
    $(this.target).find('li').each((index, element) => {

      if ($(element).hasClass('is-item-number-2')) {
        TweenMax.to(element, 0.5, {
          y: '0%',
          opacity: '1',
          display: 'block'
        })
      } else {
        $(element).css('display', 'none');
      }
    });
  }
}