export default class PickyModalDataSync {

  constructor(element, options) {
    this.syncTos = Array.prototype.slice.call(element.querySelectorAll(options.syncToEl), 0)
    this.syncFroms = Array.prototype.slice.call(element.querySelectorAll(options.syncFromEl), 0)
    this.checkBoxes = Array.prototype.slice.call(element.querySelectorAll(options.checkBoxEl), 0)
    this.numberTexts = Array.prototype.slice.call(element.querySelectorAll(options.numberTextEl), 0)
    this.resetBtns = Array.prototype.slice.call(element.querySelectorAll(options.resetBtnEl), 0)
    this.filterBtns = Array.prototype.slice.call(element.querySelectorAll(options.filterBtnEl), 0)

    // 検索時のデータ
    this.selectedData = {}

    this.init()
  }

  init() {

    this.syncFroms.forEach(syncFromEl => {
      syncFromEl.addEventListener('change', this.handleSyncFromElChange.bind(this))
    })

    this.checkBoxes.forEach(checkBox => {
      checkBox.addEventListener('change', this.handleCheckBoxChange.bind(this))
    })

    this.numberTexts.forEach(numberText => {
      numberText.addEventListener('change', this.handleNumberTextChange.bind(this))
    })

    this.resetBtns.forEach(resetBtn => {
      resetBtn.addEventListener('click', this.handleResetClick.bind(this))
    })

  }

  sync(val = null) {
    this.syncTos.forEach(syncToEl => syncToEl.value = val)
  }

  btnActive() {
    this.filterBtns.forEach(filterBtn => filterBtn.disabled = false)
  }

  btnDisabled() {
    this.filterBtns.forEach(filterBtn => filterBtn.disabled = true)
  }

  changeBtnState() {
    let isChecked = false
    let isInputed = false


    this.checkBoxes.forEach(checkBox => {
      if (checkBox.checked) isChecked = true
    })
    this.numberTexts.forEach(numberText => {
      if (numberText.value !== '') isInputed = true
    })

    isChecked || isInputed ? this.btnActive() : this.btnDisabled()
  }

  formatShowData(selectAry) {
    let counter = 0
    let showStr = ''

    selectAry.forEach((itm, index) => {
      index === 0 ? showStr = itm : counter++
    })

    return selectAry.length > 1 ? `${showStr},他${counter}件` : `${showStr}`
  }

  handleNumberTextChange(e) {
    const name = e.currentTarget.name
    this.selectedData[name] = e.currentTarget.value

    // NOTE: /assets/js/src/module/FormatPrice.js のフォーマット処理が完了後に処理する。
    setTimeout(() => this.changeBtnState())
  }

  handleCheckBoxChange() {
    const selectedBrands = []
    const selectedPickies = []

    this.checkBoxes.forEach(checkBox => {
      if (checkBox.checked) {
        if (checkBox.getAttribute('name') === 'brand') selectedBrands.push(checkBox.value)
        if (checkBox.getAttribute('name').indexOf('filtercode') === 0) selectedPickies.push(checkBox.value)
      }
    })

    this.selectedData['brand'] = selectedBrands.join(',')
    this.selectedData['picky'] = selectedPickies.join(',')

    this.changeBtnState()
  }

  handleSyncFromElChange() {
    let selected = []

    this.syncFroms.forEach(item => {
      if (item.checked) selected.push(item.value)
    })

    this.sync(this.formatShowData(selected))
  }

  handleResetClick() {
    this.checkBoxes.forEach(item => item.checked = false)

    this.numberTexts.forEach(item => item.value = '')

    this.selectedData = { }

    this.btnDisabled()

    this.sync()
  }

}
