export default class ItemCounter {
  constructor(elem) {
    /**
     * @param {Number} this.MAX_CART_ITEM - カートの最大数
     * @param {String} this.UP - アイテムを増やす際の命令
     * @param {String} this.DOWN - アイテムを減らす際の命令
     * @constructor
    */
    this.MAX_CART_ITEM = 10
    this.UP = 'UP'
    this.DOWN = 'DOWN'
    this.init(elem)
  }

  /**
   * init
   * 初期化
   * @param {Object} btn - 増減を操作するボタン
   * @param {Object} this.CONTENT - 入力ボックス
   */
  init(elem) {
    let btn = {}
    btn['plus'] = elem.querySelector('[data-counter="plus"]')
    btn['minus'] = elem.querySelector('[data-counter="minus"]')
    this.CONTENT = elem.querySelector('[data-counter="content"]')
    this.eventBinds(btn)
  }

  /**
   * eventBinds
   * イベントハンドラ
   * @param {Object} btn - 増減を操作するボタン
   * @param {Object} this.CONTENT - 入力ボックス
   */
  eventBinds(btn) {
    btn['plus'].addEventListener('click', () => {this.counterAct(this.UP)})
    btn['minus'].addEventListener('click', () => {this.counterAct(this.DOWN)})
    this.CONTENT.addEventListener('change',() => {this.judge()})
  }

  /**
   * counterAct
   * 上限下限の範囲内で数値を加算減算してinputのvalueに格納する
   * @param {Number} number - テキストボックスに入力されている値
   * @param {Object} this.CONTENT - 入力ボックス
   * @param {String} order - 'this.UP' or 'this.DOWN'
   */
  counterAct(order) {
    let number = Number(this.CONTENT.value)
    switch (order) {
      case this.UP:
        if (number < this.MAX_CART_ITEM) number = number + 1
        break;
      case this.DOWN:
        if (number > 1) number = number - 1
        break;
      default:
        break;
    }
    this.CONTENT.value = number
  }

  /**
   * judge
   * input valueが直接入力された時に発火、入力値が上限値を超えている・下限値を下回っている時に下限値上限値に直す
   * @param {Number} TARGET - テキストボックスに入力されている値 Number型じゃない時はnullに
   */
  judge() {
    const TARGET = Number(this.CONTENT.value) || this.conversion(this.CONTENT.value)
    if (TARGET < 1 || TARGET === null) this.CONTENT.value = 1
    if (TARGET > this.MAX_CART_ITEM) this.CONTENT.value = this.MAX_CART_ITEM
  }

  /**
   * conversion
   * input valueの値が全角数値だった場合Number型に変換を行い最適化をする。
   * @param {String} val - テキストボックスに入力されている値
   * @return {Number} - テキストボックスに入力されている値 Number型じゃない時はnullに
   * @throws {null} - ひらがなやアルファベットが入っている場合
   */
  conversion(val) {
    val = val.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    val = Number(val) || null
    this.CONTENT.value = val
    return val
  }
} 