export default class RankingCategory {
  constructor(element, options){
    this.element = element
    this.options = options
    this.sliderElement = $(this.element).find('.p-top-ranking__category-slider')
    this.sliderNav = $(this.element).find('.p-top-ranking__category__item')
    this.init()
  }
  init() {
    this.setSliderNav()
    this.setSlider()
  }

  setSliderNav() {
    const _this = this
    this.sliderNav.each(function() {
      const dataId = $(this).attr("data-id")
      $(this).click(function() {
        _this.changeSlide(dataId - 1)
        $(_this.sliderNav).removeClass('is-active')
        $(this).addClass('is-active')
      })
    })
  }

  changeSlide(id) {
    $(this.sliderElement).slick('slickGoTo',id)
    setTimeout(() => {
      $('.c-product-slider__products').slick('slickGoTo',0)
    }, 500)
  }

  setSlider() {
    $(this.sliderElement).slick({
      arrows: false,
      dots: false,
      infinite: false,
      swipeToSlide: false,
      swipe: false,
      fade: true
    })
  }
}
