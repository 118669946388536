export default class StaticSideBar {
  constructor(element) {
    this.element = element
    this.topFlg = false
    this.botFlg = false
    this.stayBotFlg = false
    this.stayTopFlg = false
    this.target = {
      top: document.querySelectorAll(`[data-static-sidebar-target="top"]`)[0],
      bot: document.querySelectorAll(`[data-static-sidebar-target="bottom"]`)[0]
    }
    this.state = 'normal'
    if (this.target.bot == undefined) {
      this.target.bot = document.querySelectorAll('.l-main')[0]
    }
    console.log(this.target.top, this.target.bot)
    this.targetBotFlg = false
    this.topOffset = 105
    this.checkFlg = false
    // this.init()
  }

  init() {
    // this.target.top.addEventListener('scroll', () => this.topTarget.bind(this))
    const _this = this

    this.heightCheck()

    if (this.elementHeight < window.innerHeight) {
      $(window).on('wheel', function(e) {
        _this.topFixOnly()
      })
    } else if (this.elementHeight > window.innerHeight) {
      $(window).on('wheel', function(e) {
        _this.scroll(e)
        _this.botTarget()
        _this.topTarget()
      })
    }

    window.addEventListener('resize', this.heightCheck.bind(this))
  }

  heightCheck() {
    this.elementHeight = $(this.element).outerHeight()
  }

  scroll(e) {
    const _delta = e.originalEvent.deltaY

    const topBorder = this.element.getBoundingClientRect().top + this.topOffset
    const botBorder = this.element.getBoundingClientRect().bottom + 30
    const yOffset = window.pageYOffset - window.innerHeight

    // console.log(`topborder ${topBorder}`)
    // console.log(`yoffset ${window.pageYOffset}`)

    if (_delta > 0) { // scrolling down
      if (botBorder < window.innerHeight ) {
        if (!this.botFlg) {
          this.botFlg = true
          this.stayBotFlg = false
          this.fixBot()
        }
      }
      if (this.stayTopFlg) {
        this.stayFlg = false
        this.topFlg = false
        this.botFlg = false
        this.targetBotFlg = false
        this.stay() 
      }
    }
    if (_delta < 0) { // scrolling up
      if (!this.stayBotFlg) {
        this.stayBotFlg = true
        if (this.state !== 'staybottom') this.botFlg = false
        this.stay() 
      }
      if (topBorder > this.topOffset ) {
        if (!this.topFlg) {
          this.topFlg = true
          this.stayTopFlg = true
          this.fixTop()
        }
      }
      if (window.pageYOffset == 0) this.reset()
    }
  }

  topTarget() {
    const topTargetTop = this.target.top.getBoundingClientRect().top
    if (topTargetTop > this.topOffset) {
      this.reset()
    }
  }

  botTarget() {
    const botTargetTop = this.target.bot.getBoundingClientRect().top
    this.offsetTop = $(this.target.bot).offset().top
    this.maxOffset = this.offsetTop - $(this.element).outerHeight()

    if (botTargetTop < window.innerHeight) {
      if (!this.targetBotFlg) {
        this.targetBotFlg = true
        this.botFlg = true
        this.maxHeight = 
        this.stayBottom()
      }
    }
    if (botTargetTop > window.innerHeight) {
      if (this.targetBotFlg) {
        this.targetBotFlg = false
        this.botFlg = false
      }
    }
  }

  fixTop() {
    console.log('fixtop')
    $(this.element).css({
      'position': 'fixed',
      'top': this.topOffset,
      'bottom': 'auto'
    })
    this.state = 'fixtop'
  }

  fixBot() {
    console.log('fixbot')
    $(this.element).css({
      'position': 'fixed',
      'bottom': 30,
      'top': 'auto'
    })
    this.state = 'fixbot'
  }

  stay() {
    console.log('stay')
    const offset = $(this.element).offset()
    $(this.element).css({
      'position': 'absolute',
      'top': `${offset.top}px`,
      'bottom': 'auto'
    })
    this.state = 'stay'
  }

  stayBottom() {
    console.log('stay bottom')
    $(this.element).css({
      'position': 'absolute',
      'top': `${this.maxOffset}px`,
      'bottom': 'auto'
    })
    this.state = 'staybottom'
  }

  reset() {
    console.log('reset')
    $(this.element).css({
      'position': 'relative',
      'top': `auto`,
      'bottom': 'auto'
    })
    this.topFlg = false
    this.botFlg = false
    this.stayBotFlg = false
    this.stayTopFlg = false
    this.targetBotFlg = false
    this.state = 'normal'
  }

  // エレメントの高さが画面より小さい時
  topFixOnly() {
    const offsetTop = $(this.element).offset().top
    const borderTop = window.pageYOffset + 105
    const resetPoint = $(this.target.top).offset().top

    if (offsetTop < borderTop) {
      if (!this.topFlg) {
        this.topFlg = true
        this.fixTop()
      }
    }
    if (borderTop < resetPoint) {
      if (this.topFlg) {
        this.topFlg = false
        this.reset()
      }
    }
  }
}
