import TweenMax from '../../libs/_TweenMax.min.js';
import Hammer from '../../libs/_hammer.js';

export default class MyPageSwipe {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.target = document.querySelectorAll(`[data-module-target="${this.options.target}"]`)[0];
    this.scrollArea = this.element.parentNode;
    this.beforePositionX;
    this.afterPositionX;
    this.maxPositionX = 0;
    this.nowPositionX = 0;
    this.lastItemWidth;
    this.maxPositionX;
    this.itemsStart;
    this.itemsEnd;
    this.init();
  }

  //initialize
  init() {
    this.appendArrow();
    this.eventBind();
    this.itemLength();
  }

  //==============================
  //イベントバインド
  //==============================
  eventBind() {
    const hammer = new Hammer(this.scrollArea);

    hammer.on('panstart', (event) => {
      this.beforePositionX = event.deltaX;
    });
    hammer.on('panend', (event) => {
      this.afterPositionX = event.deltaX;
      this.scrollEvents();
    });
    hammer.on('pan', (event) => {
      this.afterPositionX = event.deltaX;
      this.scrollEvents();
    });
  }

  appendArrow() {
    const arrowPrev = document.createElement('div');
    arrowPrev.classList.add('c-swipe-arrow');
    arrowPrev.classList.add('is-prev');
    console.log();
    const arrowNext = document.createElement('div');
    arrowNext.classList.add('c-swipe-arrow');
    arrowNext.classList.add('is-next');
    console.log(arrowNext);
  }

  //==============================
  //最大移動量を算出する
  //==============================
  itemLength() {
    const itemsNode = this.target.querySelectorAll('[data-module="MyPageSwipe"]');
    const items = Array.prototype.slice.call(itemsNode, 0);

    console.log(items);

    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      if (i === 0) {
        this.itemsStart = element.getBoundingClientRect().left;
      } else if (i === items.length - 1) {
        this.itemsEnd = element.getBoundingClientRect().right;
      }
      this.lastItemWidth = $(element).outerWidth(true);
    }
    //↓↓↓最大移動距離↓↓↓↓
    this.maxPositionX = (this.itemsEnd - this.itemsStart - this.target.clientWidth) * -1;
  }

  //==============================
  //スクロールイベント
  //==============================
  scrollEvents() {
    const calcMoving = (this.beforePositionX - this.afterPositionX) * -1;

    TweenMax.set(this.element, {
      x: '+=' + calcMoving + 'px',
      ease: Power4.easeOut,
      onComplete: () => {
        this.beforePositionX = this.afterPositionX;
        this.nowPositionX += calcMoving;
        if (this.nowPositionX >= 1) {
          this.defaultPosition();
        } else if (this.maxPositionX > this.nowPositionX) {
          this.maxPosittion();
        }
      }
    });
  }

  //==============================
  //初期位置に戻す
  //==============================
  defaultPosition() {
    TweenMax.to(this.element, 0.5, {
      x: '0%',
      ease: Power4.easeOut,
      onComplete: () => {
        this.nowPositionX = 0;
      }
    });
  }

  //==============================
  //最大移動位置に戻す
  //==============================
  maxPosittion() {
    TweenMax.to(this.element, 0.5, {
      x: this.maxPositionX + 'px',
      ease: Power4.easeOut,
      onComplete: () => {
        this.nowPositionX = this.maxPositionX;
      }
    });
  }
}