export default class BrandTopSlider {
  constructor(element, options){
    this.element = element
    this.options = options
    this.sliderElement = $(this.element).find('.c-product-slider__products')
    this.sliderBar = $(this.element).find('.c-product-slider__bar__indicator')
    this.sliderNext = $(this.element).find('.c-product-slider__arrow--right')
    this.sliderPrev = $(this.element).find('.c-product-slider__arrow--left')
    
    this.centerMode = this.options.centerMode || false
    this.slidesToScroll = this.options.slidesToScroll || 1
    this.slidesToShow = this.options.slidesToShow || 1
    this.slidesToShowSp = this.options.slidesToShowSp || 1
    this.infinite = this.options.infinite || false
    this.vWidth = this.options.vWidth || false
    this.arrows = this.options.arrows || false
    this.sliderType = this.options.sliderType || 'normal'
    this.customScroll = this.options.customScroll || 5
    this.currentSlide = 0
    this.init()
  }
  init() {
    this.sliderType === 'ranking' ? this.setRankingSlider() : this.setSlider()
  }

  setSlider() {
    $(this.sliderElement).on('init', (event, slick, currentSlide) => {
      this.setBarSize(slick.slideCount)
      this.setArrows(slick, currentSlide)
    })

    $(this.sliderElement).slick({
      dots: false,
      arrows: true,
      infinite: this.infinite,
      focusOnSelect: false,
      swipeToSlide: true,
      prevArrow: this.sliderPrev,
      nextArrow: this.sliderNext,
      centerMode: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: this.vWidth,
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 1
        }
      }],
    })

    $(this.sliderElement).on('afterChange', (event, slick, currentSlide, nextSlide, slideCount) => {
      this.currentSlide = currentSlide
      this.slideEnd = slick.slideCount
      this.animateBar(currentSlide, slick.slideCount)
    })
  }

  setRankingSlider() {
    $(this.sliderElement).on('init', (event, slick, currentSlide) => {
      this.setBarSize(slick.slideCount)
      this.setArrows(slick, currentSlide)
    })

    $(this.sliderElement).slick({
      dots: false,
      arrows: true,
      infinite: this.infinite,
      focusOnSelect: false,
      swipeToSlide: true,
      prevArrow: this.sliderPrev,
      nextArrow: this.sliderNext,
      centerMode: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: this.vWidth,
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 1
        }
      }],
    })

    $(this.sliderElement).on('afterChange', (event, slick, currentSlide, nextSlide, slideCount) => {
      this.currentSlide = currentSlide
      this.slideEnd = slick.slideCount
      this.animateBar(currentSlide, slick.slideCount)
    })
  }

  setBarSize(slideCount) {
    const size = 100/(slideCount-this.slidesToShow+1)
    this.sliderBar.css('width', `${size}%`)
  }

  animateBar(currentSlide, slideCount) {
    const num = currentSlide
    const total = (slideCount-this.slidesToShow+1)
    const distance = num/total
    this.sliderBar.css('left', `${distance*100}%`)
  }

  setArrows(slick) {
    $(this.sliderNext).on('click', () => {
      if (this.currentSlide%this.customScroll !== 0) {
        $(this.sliderElement).slick('slickGoTo', this.currentSlide + 1)
      } else if (this.currentSlide%this.customScroll == 0) {
        $(this.sliderElement).slick('slickGoTo', this.currentSlide + this.customScroll)
      } else {
        $(this.sliderElement).slick('slickGoTo', slick.slideCount)
      }
      
    })
    $(this.sliderPrev).on('click', () => {
      if (this.currentSlide%this.customScroll !== 0) {
        $(this.sliderElement).slick('slickGoTo', this.currentSlide - 1)
      } else if (this.currentSlide%this.customScroll == 0) {
        $(this.sliderElement).slick('slickGoTo', this.currentSlide - this.customScroll)
      } else {
        $(this.sliderElement).slick('slickGoTo', 0)
      }
    })
  }
}
