export default class ToTopScroll {
  constructor(element, options){
    this.element = element
    this.init()
  }
  init() {
    this.setTopScroll()
  }
  setTopScroll() {
    const _this = this
    $(function(){
      $(_this.element).click(function(){
        var speed = 1000;
        $("html, body").animate({scrollTop:0}, speed, "swing");
        return false;
      });
    });
  }
}
