// import SampleModule from './sample-module'
import IncludeHeader from './IncludeHeader'
import IncludeFooter from './IncludeFooter'
import ItemCounter from './ItemCounter'
import KeyVisual from './KeyVisual'
import KvSlider from './KvSlider'
import MyPageTabs from './MyPageTabs'
import MyPageSwipe from './MyPageSwipe'
import TopProductsJs from './TopProductsJs'
import TopContentsSlider from './TopContentsSlider'
import BrandTopSlider from './BrandTopSlider'
import TopFixedBg from './TopFixedBg'
import Pager from './Pager'
import Accordion from './Accordion'
import DetailSlider from './DetailSlider'
import DetailSelection from './DetailSelection'
import RankingCategory from './RankingCategory'
import Gradient from './Gradient'
import ProductSort from './ProductSort'
import RefineSearch from './RefineSearch'
import TruncateText from './TruncateText'
import Loading from './Loading'
import HeaderShrink from './HeaderShrink'
import StaticSideBar from './StaticSideBar'
import InsertModal from './InsertModal'
import InsertModalDataSync from './InsertModalDataSync'
import AnchorScroll from './AnchorScroll'
import CommonModal from './CommonModal'
import Favorite from './Favorite'
import Favorite2 from './Favorite2'
import AllAccordion from './AllAccordion'
import ModalSlider from './ModalSlider'
import PickyModalDataSync from './PickyModalDataSync'
import FormatPrice from './FormatPrice'
import Notification from './Notification'
import PullDown from './PullDown'
import SortModal from './SortModal'
import CampaignPickupSlider from './CampaignPickupSlider'
import ProductSlider from './ProductSlider'
import FixBottom from './FixBottom'
import YmkPager from './YmkPager'
import AccordionGradual from './AccordionGradual'

export default class {
  constructor() {
    Array.prototype.forEach.call(document.querySelectorAll('[data-module]'), element => {
      const keys = element.getAttribute('data-module').split(/\s+/)
      const opts = element.getAttribute('data-options') || null

      keys.forEach(key => {
        const Module = require(`./${key.charAt(0).toUpperCase() + key.slice(1)}`).default
        const options = opts ? (keys.length > 1) ? JSON.parse(opts)[key] : JSON.parse(opts) : {}

        if (Module !== void 0)
          return new Module(element, options)
      })
    })
    // this.module = document.querySelectorAll('[data-module]')
    // this.moduleKeys()
  }
  moduleKeys() {
    Array.prototype.forEach.call(this.module, element => {
      const MODULE_KEY = element.getAttribute('data-module')
      const MODULE_OPTS = element.getAttribute('data-options') || null
      const Components = require(`./${MODULE_KEY}`).default
      const OPTIONS = (this.isJSON(MODULE_OPTS)) ? JSON.parse(MODULE_OPTS) : (MODULE_OPTS) ? MODULE_OPTS : null
      if (Components !== void 0) {
        console.log(OPTIONS)
        return new Components(element, OPTIONS)
      }
    })
  }
  isJSON(arg) {
    return /{/.test(arg)
  }
}

// Array.prototype.forEach.call(document.querySelectorAll('[data-module]'), element => {
//   console.log(element)
//   const keys = element.getAttribute('data-module').split(/\s+/)
//   const opts = element.getAttribute('data-options') || null

//   keys.forEach( key => {
//     const Module = require(`./${key.charAt(0).toUpperCase() + key.slice(1)}`).default
//     console.log(Module)
//     const options = opts ? (keys.length > 1) ? JSON.parse(opts)[key] : JSON.parse(opts) : {}

//     if (Module !== void 0)
//       return new Module(element, options)
//   })
// })