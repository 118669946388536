export default class PullDown {
  constructor(element, options) {
    this.wrapper = element
    this.triggers = Array.prototype.slice.call(element.querySelectorAll(options.triggerEl), 0)
    this.targets = Array.prototype.slice.call(element.querySelectorAll(options.targetEl), 0)
    this.values = Array.prototype.slice.call(element.querySelectorAll(options.syncVal), 0)
    this.init()
  }
  init() {

    this.triggers.forEach( trigger => {
      trigger.addEventListener('click', this.handleTriggerClick.bind(this))
    })
    
    this.values.forEach(value => {
      value.addEventListener('click', this.handleValueClick.bind(this))
    })

    this.wrapper.addEventListener('mouseleave', this.handleWrapperMouseLeave.bind(this))

  }

  toggle() {
    $(this.wrapper).toggleClass('is-open')
  }

  handleTriggerClick() {
    this.toggle()
  }

  handleValueClick(e) {
    const value = e.currentTarget.value

    this.triggers.forEach(trigger => trigger.value = value)

    this.toggle()
  }

  handleWrapperMouseLeave() {
    if (!this.wrapper.classList.contains('is-open')) return

    this.toggle()
  }
}
