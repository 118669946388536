export default class FixBottom {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.parent = this.element.querySelectorAll(`[data-module-fixBottom-parent]`)[0];
    this.child = this.element.querySelectorAll(`[data-module-fixBottom-child]`)[0];
    this.init();
  }

  //initialize
  init() {
    this.eventBind();
    this.parent.style.position = 'relative';
    this.fixItem();
  }

  eventBind() {
    $(window).on('scroll resize', () => {
      this.fixItem();
    });
  }

  //==============================
  //this.parentの領域内で、画面下部に張り付きます
  //==============================

  fixItem() {
    let scroll = window.pageYOffset || document.documentElement.scrollTop;
    let windowHeight = window.innerHeight;
    let parentHeight = this.parent.offsetHeight;
    let parentTop = this.parent.offsetTop;
    let childHeight = this.child.offsetHeight;

    this.child.style.bottom = null;
    if (parentTop + parentHeight < scroll + windowHeight) {
      this.child.style.position = 'absolute';
      this.child.style.bottom = '0';
    } else if (parentTop + childHeight < scroll + windowHeight) {
      this.child.style.position = 'fixed';
      this.child.style.bottom = '0';
    } else {
      this.child.style.position = 'static';
    }
    
  }
}