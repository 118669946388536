export default class SortModal {
  constructor(element) {
    this.element = element
    this.button = this.element.querySelectorAll('.c-sort-modal__list__item')
    this.init()
  }

  init() {
    const _this = this
    this.button.forEach( item => {
      item.addEventListener('click', this.setActive.bind(this))
      item.addEventListener('touchstart', this.setActive.bind(this))
    })
  }

  setActive(e) {
    const target = e.currentTarget
    $(this.button).removeClass('is-active')
    $(target).addClass('is-active')
  }
}
