export default class Favorite2 {
  constructor(element) {
    this.element = element
    this.btn = $(this.element).find('.c-favorite-btn')
    this.heart = $(this.element).find('.c-favorite-btn__img')
    this.playFlg = false
    this.init()
  }

  init() {
    const _this = this
    $(this.element).on('click', function(){_this.animate()})
  }

  animate() {
    if ($(this.heart).hasClass('play')){
      $(this.heart).removeClass('play')
      $(this.btn).removeClass('play')
    } else {
      $(this.heart).addClass('play')
      $(this.btn).addClass('play')
    }
  }
}
