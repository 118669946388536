export default class AnchorScroll {
  constructor(element) {
    this.element = element
    this.init()
  }

  init() {
    console.log('anchor scroll')
    $(this.element).click(function(){
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $("html, body").animate({scrollTop:(position-105)}, speed, "swing");
      return false;
    });
  }
}
