import TweenMax from '../../libs/_TweenMax.min.js'

/**
 * .js-insert-modal-triggerクラスが付いているエレメントのdata-slide-action=""を取得して、アクションする。
 * data-slide-actionの種類は以下の通り。
 * - close 　インサートウインドウを全て閉じる
 * - back    インサートウインドウを一つ閉じる
 * - select  インサートウインドウを第一階層に戻す
 * 
 */
export default class InsertModal {
  constructor(element) {
    this.element = element 
    this.slider = element.querySelector('.js-myModal__slider')
    this.trigger = element.querySelectorAll('.js-insert-modal-trigger')
    this.scrollInrs = element.querySelectorAll('.p-myModal__content')
    this.activeLayer = 0
    this.baseScrollY = 0

    this.init()
  }

  init() {
    this.trigger.forEach( item => {
      item.addEventListener('click', this.handleSlideAction.bind(this))
    })
  }

  ableScroll() {
    const html = document.querySelector('html')

    html.style.position = ''
    html.style.top = this.baseScrollY + 'px'
    html.style.width = ''
    html.style.overflow = ''
    

    window.scrollTo(0, this.baseScrollY)
  }

  unableScroll() {
    const html = document.querySelector('html')

    this.baseScrollY = window.pageYOffset
    html.style.position = 'fixed'
    html.style.top = -1 * this.baseScrollY + 'px'
    html.style.width = '100%'
    html.style.overflow = 'hidden'
  }

  setScrollInrHeight() {
    const wh = window.innerHeight
    const headerH = this.element.querySelector('.p-myModal__haeder').clientHeight
    const footerH = this.element.querySelector('.p-myModal__action').clientHeight

    for (let i = 0; i < this.scrollInrs.length; i++) {
      if (this.scrollInrs[i].classList.contains('is-float-btn')) {
        this.scrollInrs[i].style.height = `${wh - headerH - footerH}px`
      } else {
        this.scrollInrs[i].style.height = `${wh - headerH}px`
      }
    } 
  }

  close() {
    this.ableScroll()

    TweenMax.to(this.slider, 0.4, {
      x: '0%',
      ease: Power1.easeOut,
      onComplete: () => {
        const targets = this.element.querySelector(`.js-insert-modal`)
        const targetAry = Array.prototype.slice.call(targets, 0)
        targetAry.forEach(target => target.classList.remove('is-show'))
        this.activeLayer = 0
      } 
    })
  }

  back() {
    if (this.activeLayer === 1) this.ableScroll()

    TweenMax.to(this.slider, 0.4, {
      x: '+=100%',
      ease: Power1.easeOut,
      onComplete: () => {
        const prevLayer = this.element.querySelector(`.js-layer_${this.activeLayer}`)
        const targets = prevLayer.children
        const targetAry = Array.prototype.slice.call(targets, 0)
        targetAry.forEach(target => target.classList.remove('is-show'))
        this.activeLayer--
      }
    })
  }

  next(key) {
    const target = this.element.querySelector(`.js-insert-modal-${key}`)
    target.classList.add('is-show')

    TweenMax.to(this.slider, 0.4, {
      x: '-=100%',
      ease: Power1.easeOut,
      onComplete: () => {
        if (!this.activeLayer) {
          this.unableScroll()
          this.setScrollInrHeight()
        }
        this.activeLayer++
      } 
    })
  }

  select(key) {
    TweenMax.to(this.slider, 0.4, {
      x: '-100%',
      ease: Power1.easeOut,
      onComplete: () => {
        const target = document.querySelectorAll('.js-insert-modal:not(.js-insert-modal-index)')
        const targetAry = Array.prototype.slice.call(target, 0)
        targetAry.forEach(target => target.classList.remove('is-show'))
        this.activeLayer = 1
      }
    })
  }

  handleSlideAction(e) {
    const targetKey = e.currentTarget.getAttribute('data-slide-action')

    switch (targetKey) {
      case 'close':  this.close(); break;
      case 'back':   this.back(); break;
      case 'select': this.select(); break;
      default:       this.next(targetKey);
    }
  }
}
