export default class TruncateText {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.init();
  }

  //initialize
  init() {
    this.sharpingText();
  }

  //==============================
  //文字列の加工
  //==============================
  sharpingText() {
    if (this.element.textContent.trim().length > this.options.length) {
      let text = this.element.textContent.trim().slice(0, this.options.length - 1)
      text += '…'
      this.element.textContent = text
    }
  }
}