import TweenMax from '../../libs/_TweenMax.min.js'

export default class Gradient {
  constructor(element) {
    this.element = element
    this.playFlg = false
    this.init()
  }

  init() {
    this.animate()
  }

  animate() {
    TweenMax.to(this.element, 9, {
      backgroundPosition: '50% 100%',
      onComplete: () => {
        TweenMax.to(this.element, 9, {
          backgroundPosition: '0% 0%',
          onComplete: () => {
            TweenMax.to(this.element, 9, {
              backgroundPosition: '50% 100%',
              onComplete: () => {
                TweenMax.to(this.element, 9, {
                  backgroundPosition: '0% 0%',
                  onComplete: () => {
                    TweenMax.to(this.element, 9, {
                      backgroundPosition: '50% 100%',
                      onComplete: () => {
                        TweenMax.to(this.element, 9, {backgroundPosition: '0% 0%'})
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }
    })
  }
}
