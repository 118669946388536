export default class DetailSelection {
  constructor(element, options){
    console.log(options)
    this.element = element
    this.options = options
    this.slider = $(this.element).find(".p-product-detail__color__choice__inner")
    this.init()
  }
  init(){
    this.slickStart()
    this.setNavi()
  }

  slickStart() {
    $(this.slider).on('init', (event, slick) => {
    })
    
    $(this.slider).slick({
      slidesToShow: 1,
      arrows: false,
      dots: false,
      fade: true,
      draggable: false,
      infinite: false,
      // responsive: [{
      //   breakpoint: 900,
      //   settings: {
      //     asNavFor: null,
      //     draggable: true,
      //     dots: true,
      //     swipeToSlide: true,
      //   }
      // }],
    });
  }

  setNavi() {
    const _this = this
    this.btn = $(this.element).find('.p-product-detail__color__group__item').on('click', function(){
      const id = $(this).attr('data-navi-id')
      const area = $(this).parent().parent().parent().find('.p-product-detail__color__title')
      $('.p-product-detail__color__group__item').removeClass('is-active')
      $(this).addClass('is-active')
      $(area).addClass('has-active-item')
      _this.changeSlide(id)
    })
  }

  changeSlide(id) {
    $(this.slider).slick('slickGoTo', id)
  }
}
