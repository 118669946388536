import TruncateText from './TruncateText'
import Gradient from './Gradient'

export default class ProductSlider {
  constructor(element, options){
    this.element = element
    this.options = options
    this.timeoutTimer = 5000
    
    this.centerMode = this.options.centerMode || false
    this.slidesToScroll = this.options.slidesToScroll || 1
    this.slidesToShow = this.options.slidesToShow || 1
    this.slidesToShowSp = this.options.slidesToShowSp || 1
    this.infinite = this.options.infinite || false
    this.vWidth = this.options.vWidth || false
    this.sliderType = this.options.sliderType || 'normal'
    this.centerPadding = this.options.centerPadding || '0'
    this.karteFlag = this.options.karteFlag
    this.init()
  }
  init() {
    if (this.karteFlag == undefined) {
      this.bindEvents()
    } else {
      TweenMax.set(this.element, { opacity: 0 })
      this.watchFlag()
    }
  }

  watchFlag() {
    const _this = this
    this.karteTimeout()
    const karteCheck = function karteCheck() {
      setTimeout(() => {
        const stat = window.localStorage.getItem(_this.karteFlag)
        if (stat === null) {
          karteCheck()
        } else {
          // console.log('clear karteTimer')
          clearTimeout(_this.karteTimer)
          _this.bindEvents()
        }
      },100)
    }
    karteCheck()
  }

  karteTimeout() {
    this.karteTimer = setTimeout(() => {
      this.bindEvents()
    }, this.timeoutTimer)
  }

  bindEvents() {
    this.sliderElement = $(this.element).find('.c-product-slider__products')
    this.sliderBar = $(this.element).find('.c-product-slider__bar__indicator')
    this.sliderNext = $(this.element).find('.c-product-slider__arrow--right')
    this.sliderPrev = $(this.element).find('.c-product-slider__arrow--left')
    TweenMax.set(this.element, { opacity: 1 })
    this.setSlider()
  }

  setSlider() {
    const _this = this
    $(this.sliderElement).on('init', (event, slick, currentSlide) => {
      // this.setBar(slick.slideCount)
      // this.setArrows(slick, currentSlide)
      // console.log($('.p-top-ranking__category-slider'))
      $('.p-top-ranking__category-slider').slick('reinit')
      _this.renew()
    })

    $(this.sliderElement).slick({
      dots: true,
      arrows: true,
      infinite: this.infinite,
      focusOnSelect: false,
      swipeToSlide: true,
      prevArrow: this.sliderPrev,
      nextArrow: this.sliderNext,
      centerMode: this.centerMode,
      centerPadding: this.centerPadding,
      slidesToShow: this.slidesToShow,
      slidesToScroll: this.slidesToScroll,
      variableWidth: this.vWidth
    })

    $(this.sliderElement).on('afterChange', (event, slick, currentSlide, nextSlide, slideCount) => {
      this.currentSlide = currentSlide
      this.slideEnd = slick.slideCount
      // this.animateBar(currentSlide, slick.slideCount)
      // this.setArrows(currentSlide, slick.slideCount)
    })
  }

  setArrows(current, total) {
    const currentPos = current + 1
    if (currentPos == 1) {
      $(this.sliderPrev).css('display', 'none')
      $(this.sliderNext).css('display', 'block')
    } else if (currentPos == total) {
      $(this.sliderPrev).css('display', 'block')
      $(this.sliderNext).css('display', 'none')
    } else {
      $(this.sliderPrev).css('display', 'block')
      $(this.sliderNext).css('display', 'block')
    }
  }

  renew() {
    Array.prototype.forEach.call(this.element.querySelectorAll('[data-module]'), element => {
      const keys = element.getAttribute('data-module').split(/\s+/)
      const opts = element.getAttribute('data-options') || null
      keys.forEach(key => {
        const Module = require(`./${key.charAt(0).toUpperCase() + key.slice(1)}`).default
        const options = opts ? (keys.length > 1) ? JSON.parse(opts)[key] : JSON.parse(opts) : {}
        if (Module !== void 0)
          return new Module(element, options)
      })
    })
  }

  // setBar(slideCount) {
  //   if (slideCount == 1) {
  //     $(this.element).find('.c-product-slider__bar').hide()
  //   }
  //   const size = 100/(slideCount-this.slidesToShow+1)
  //   this.sliderBar.css('width', `${size}%`)
  // }

  // animateBar(currentSlide, slideCount) {
  //   const num = currentSlide
  //   const total = (slideCount-this.slidesToShow+1)
  //   const distance = num/total
  //   this.sliderBar.css('left', `${distance*100}%`)
  // }

  // setArrows(slick) {
  //   $(this.sliderNext).on('click', () => {
  //     if (this.currentSlide%this.customScroll !== 0) {
  //       $(this.sliderElement).slick('slickGoTo', this.currentSlide + 1)
  //     } else if (this.currentSlide%this.customScroll == 0) {
  //       $(this.sliderElement).slick('slickGoTo', this.currentSlide + this.customScroll)
  //     } else {
  //       $(this.sliderElement).slick('slickGoTo', slick.slideCount)
  //     }
      
  //   })
  //   $(this.sliderPrev).on('click', () => {
  //     if (this.currentSlide%this.customScroll !== 0) {
  //       $(this.sliderElement).slick('slickGoTo', this.currentSlide - 1)
  //     } else if (this.currentSlide%this.customScroll == 0) {
  //       $(this.sliderElement).slick('slickGoTo', this.currentSlide - this.customScroll)
  //     } else {
  //       $(this.sliderElement).slick('slickGoTo', 0)
  //     }
  //   })
  // }
}
