export default class AccordionBrandFooter {
  constructor(element, options) {
    this.element = element;
    this.options = options;
    this.flg = true;
    this.trigger = this.element.querySelectorAll(`[data-module-accordion-brand-footer-trigger="${this.options.target}"]`)[0];
    this.target = this.element.querySelectorAll(`[data-module-accordion-brand-footer-target="${this.options.target}"]`)[0];
    this.ttl = this.element.getElementsByClassName("l-brandfooter__links__ttl");
    this.targetInner = this.target.firstElementChild;
    this.targetInnerHeight = this.targetInner.clientHeight;
    this.init();
  }

  //initialize
  init() {
    this.eventBind();
    // this.setAccordion();
  }

  //==============================
  //イベントバインド
  //==============================
  eventBind() {
    $(this.trigger).on('click', () => {
      this.switchAccordion();
    });
  }

  //==============================
  //アコーディオンの初期状態設定
  //==============================
  setAccordion() {
    this.trigger.style.cursor = 'pointer';
  }

  //==============================
  //アコーディオン切り替え
  //==============================
  switchAccordion() {
    if (!this.flg) {
      $(this.trigger).addClass('js-accordion-open');
      $(this.target).removeClass('js-accordion-close');
      $(this.ttl).removeClass('js-accordion-close');
      this.flg = true;
    } else {
      $(this.trigger).removeClass('js-accordion-open');
      $(this.target).addClass('js-accordion-close');
      $(this.ttl).addClass('js-accordion-close');
      this.flg = false;
    }
  }
}