/**
 * 金額を入力した際に、以下の値にフォーマットする。
 * - ３桁ごとにコンマを付与
 * - 頭に￥マークをつける。
 */
export default class FormatPrice {

  constructor(element) {
    this.element = element
    this.init()
  }

  init() {
    this.element.addEventListener('change', this.handleFormat.bind(this))
  }
  
  handleFormat() {
    const val = this.element.value
    if (val === '') return

    const valInt = parseInt(val, 10)

    if (isNaN(valInt)) {
      this.element.value = null
      return
    }

    const formatVal = valInt.toLocaleString('jp', {
      style: 'currency',
      currency: 'JPY'
    })

    this.element.value = formatVal
  }

}