import TweenMax from '../../libs/_TweenMax.min.js'
import TruncateText from './TruncateText'
import Gradient from './Gradient'

export default class Fade {
  constructor(element) {
    this.element = element
    this.playFlg = false
    this.init()
  }

  init() {
    TweenMax.set(this.element, { opacity: 0, y:40, scale: 0.9 })
    this.renew()
    window.addEventListener('scroll', () => this.enter())
  }

  enter() {
    const topBorder = this.element.getBoundingClientRect().top
    const delay = Math.random() * 1
    if(topBorder < window.innerHeight / 4 * 3) {
      if(!this.playFlg){
        this.playFlg = true;
        TweenMax.to(this.element, 0.5, { opacity: 1, ease: Power2.easeOut, delay: delay, y:0 })
        TweenMax.to(this.element, 0.5, { scale: 1, ease: Elastic.easeOut.config(1, 0.3), delay: delay })
      }
    }
  }

  renew() {
    // console.log('renew init')
    Array.prototype.forEach.call(this.element.querySelectorAll('[data-module]'), element => {
      const keys = element.getAttribute('data-module').split(/\s+/)
      const opts = element.getAttribute('data-options') || null
      // console.log(element)
      keys.forEach(key => {
        const Module = require(`./${key.charAt(0).toUpperCase() + key.slice(1)}`).default
        const options = opts ? (keys.length > 1) ? JSON.parse(opts)[key] : JSON.parse(opts) : {}
        if (Module !== void 0)
          return new Module(element, options)
      })
    })
  }
}
