import HamburgerMenu from './HamburgerMenu'
import AccordionBrandFooter from './AccordionBrandFooter'
import Notification from './Notification'

export default class IncludeHeader {
  constructor(element, options) {
    this.element = element
    this.options = options
    this.path = $(this.element).attr('data-path');
    this.init()
  }
  init() {
    $(this.element).load(this.path, () => {
      this.renew()
    });
  }

  renew() {
    Array.prototype.forEach.call(this.element.querySelectorAll('[data-module]'), element => {
      const keys = element.getAttribute('data-module').split(/\s+/)
      const opts = element.getAttribute('data-options') || null
      keys.forEach(key => {
        const Module = require(`./${key.charAt(0).toUpperCase() + key.slice(1)}`).default
        const options = opts ? (keys.length > 1) ? JSON.parse(opts)[key] : JSON.parse(opts) : {}
        if (Module !== void 0)
          return new Module(element, options)
      })
    })
    const notifElm = document.querySelector('.l-notification')
    new Notification(notifElm)
  }
}